/* ================
// Buttons
// ============= */

.button {
  display: inline-block;
  vertical-align: top;
  border: 0;
  margin: 0 em(10) em(10) 0;
  padding: em(10) em(20);
  cursor: pointer;
  text-align: center;
  // background: #eee;

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
  }

  &:focus,
  &:hover {}

  &-modifier {}
}


// /* ================
// // Skip Link
// // ============= */

// .skip-link {
//   @include visually-hidden;
//   display: inline-block;

//   &:focus {
//     @include reset-visually-hidden;
//   }
// }
// .panzoom__controls {
//   box-shadow: 0 2px 4px rgba(0,0,0,0.2),0 -1px 0px rgba(0,0,0,0.02);
//   border-radius: 0;
//   background-color: #fff;
//   padding: 0;
//   top: 10px;
//   right: 10px;
//   right: calc(env(safe-area-inset-right) + 10px);
//   left: auto;
//   .fullscreenToggle, .zoomIn, .zoomOut {
//     background: none;
//   }
//   .panzoom__button {
//     // appearance: none;
//     // border-radius: 0;
//     // border: none;
//     // font-weight: bold;
//     // // font-size: 1.2rem;
//     // // line-height: 1.2rem;
//     // // height: 2rem;
//     background-color: #fff;
//     // vertical-align: middle;
//     // vertical-align: middle;
//     // margin: 0;
//     // padding: 5px;
//     // border-bottom: 1px solid rgba(0,0,0,0.2);
//     // position: relative;
//     // display: block;
//     // &:last-of-type {
//     //   border-bottom: none;
//     // }
//     svg path {
//       // stroke: #000;
//       // stroke-width: 4px;
//     }
//     // &::before {
//     //   position: absolute;
//     //   top: 5px;
//     //   left: 5px;
//     //   right: 5px;
//     //   bottom: 5px;
//     //   display: block;
//     //   font-size: 45px;
//     //   text-align: center;
//     //   /* width: 100%; */
//     //   /* height: 100%; */
//     //   // vertical-align: middle;
//     //   line-height: 0.5;
//     //   font-weight: lighter;
//     // }
//   }
//   .panzoom__button.panzoom__button--zoomIn {
//     // background-position: 5px 5px;
//     // &::before {
//     //   content: "+";
//     // }
//   }
//   .panzoom__button.panzoom__button--zoomOut {
//     // background-position: -25px 5px;
//     // &::before {
//     //   content: '−';
//     // }
//   }
// }