#tables {
  display: flex;
  flex-flow: column nowrap;
  // justify-content: space-between;
  

  h3 {
    color: #fff;
    padding: 1em 0.5em;
    margin: 20px 10px 10px;
    background-color: lighten($primary-colour, 15%);
  }
  .button {
    text-align: left;
  }
  .compartment {
    width: 100%;
    max-width: 1000px;
    // padding-right: 1em;
    // padding-left: 1em;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;

    // align-items: stretch;
    & > div {
      // min-width: 50%;
      box-sizing: border-box;
      flex: 1 1 50%;
    }

    &>div:first-of-type {
      // padding-right: 1em;
    }

    div.crisistypen {
      flex-basis: 100%;
      @media screen and (min-width: 768px) {
        flex-basis: 66%;
      }
      h3 {
        background-color: $primary-colour;
      }

      .tables-inner {
        padding-bottom: 2em;
      }

      .tables-inner table {
        tr {
          background-color: lighten($primary-colour, 55%);
          // &:nth-child(odd) {
          //   background-color: #edf1f7;
          // }

          // &:nth-child(even) {
          //   background-color: #f5f7fb;
          // }
        }

        th {
          background-color: #d9e3f1;
        }
      }
    }

    div.gemeenten {
      flex-basis: 100%;
      @media screen and (min-width: 768px) {
        flex-basis: 33%;
      }

      h3 {
        background-color: #F7921E;
      }

      .tables-inner table {
        tr {
          background-color: #fdf5f0;
          // &:nth-child(odd) {
          //   background-color: #fbebdb;
          // }

          // &:nth-child(even) {
          //   background-color: #fdf5f0;
          // }
        }
      }
    }

    div.aanjagers {
      flex-basis: 100%;
      @media screen and (min-width: 768px) {
        flex-basis: 33%;
      }

      // flex-basis: 33%;
      h3 {
        background-color: #78b65b;
      }

      .tables-inner table {
        tbody {
          display: flex;
          flex-flow: row wrap;
          align-content: flex-start;
          justify-content: flex-start;
        }

        tr {
          flex: 1 1 100%;
          @media screen and (min-width: 768px) {
            flex: 0 1 33.3%;
          }
          padding: 0 10px;
          box-sizing: border-box;

          &:nth-child(odd) {
            background-color: #e3efd9;
          }

          &:nth-child(even) {
            background-color: #f8fbf6;
          }
        }
      }
    }

    & div.tables-inner {
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      align-content: stretch;

      // align-items: stretch;
      // column-count: 2;
      & > div {
        // width: 45%;
        flex: 1 1 100%;
        @media screen and (min-width: 768px) {
          flex: 1 1 50%
        }
        // flex-grow: 2;
        // padding: 0 1em 0 0;
        box-sizing: border-box;
      }

      table,
      th,
      td {
        border: none;
        border-collapse: collapse;
      }

      table {
        margin-top: 2em;

        &:first-of-type {
          margin-top: 0;
        }

        a {
          color: #000;
          text-decoration: none;

          // @media (hover:hover) {
          &:hover {
            color: rgba(0, 0, 0, 0.8);
          }

          // }
        }

        width: 100%;

        tr,
        td {
          border: 0;
          margin: 0;
        }

        tr {
          // padding: 1em;
        }

        td {
          padding: 1em 0.5em;
        }

        th {
          padding: 1em 0.5em;
          text-align: left;
        }
      }
    }
  }

  .break {
    display: none;
  }
}

#tables {
  transition: bottom 0.3s, background-color 0.3s, height 0.3s;
  background-color: rgba(255, 255, 255, 0);
  max-height: 100%;
  // display: block;
}

body.js #tables {
  z-index: 10;
  bottom: 0;
  background-color: rgba(255, 255, 255, 1);
  // top: auto;
  overflow: auto;
  width: 100%;
  position: absolute;
  max-height: calc(100% - 50px);
  height: calc(100% - 50px);

  @media screen and (min-width: 545px) {
    max-height: calc(100% - 64px);
    height: calc(100% - 64px);
  }
}

body.js #tables.hidden {
  // display: block;
  // top: 100%;
  bottom: -100%;
  width: 100%;
  position: absolute;
  height: 0;
}

#documents.compartment, #footer.compartment {
  // padding: 1em 10px;
  a {
    color: #505050;
  }
  .logo {
    background-color: #fff;
    position: relative;
    flex: 0 1 390px;
    img {
      display: block;
      height: 200px;
      width: 370px;
      padding: 10px;
      max-height: 200px;
      max-width: 100%;
      object-fit: contain;
      // height: 200px;
      // width: 300px;
      // max-height: 200px;
      // max-width: 200px;
      // padding-left: 0;
    }
    // .triangle {
    //   // content: "";
    //   display: block;
    //   height: 100%;
    //   box-sizing: border-box;
    //   background-color: inherit;
    //   aspect-ratio: 1 / 1;
    //   /* margin-left: -50%; */
    //   // position: absolute;
    //   // top: 0;
    //   // right: 0;
    //   border-left: inherit;
    //   transform: translate(99%, 0) rotate(180deg);
    //   mask: url('../assets/images/driehoek.svg');
    //   mask-mode: alpha;
    // }
  }
  .documents {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    background-color: lighten($primary-colour, 57%);
    margin-top: 0;
    .document {
      margin-right: 1em;
      // color: #fff;
      // padding: 0 10px;
      display: block;
      line-height: 1.5em;
      a {
        // color: #fff;
      }

      img {
        display: block;
        margin: 10px 10px 10px 0;
        max-width: 100px;
        padding-right: 10px;
      }
    }
  }
}

#documents.compartment {
  // padding: 0 1em;
  // background-color: $primary-colour;
  // flex: 1 1 100%;
  align-content: flex-start;
  display: flex;
  flex-flow: column nowrap;
  & > div {
    flex: auto;
    padding: 10px;
    margin: 10px;
    box-sizing: border-box;
  }
}

#footer {
  margin-top: 1em;
}
#footer .footer {
  justify-content: flex-start;
  padding: 10px;
  // padding: 10px 1.5em;
  margin: 0 10px;
  background-color: $primary-colour;
  // flex: 1 1 100%;
  // &:before {
  //   content: "";
  //   height: 100%;
  //   box-sizing: border-box;
  //   background-color: inherit;
  //   aspect-ratio: 1 / 1;
  //   /* margin-left: -50%; */
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   border-left: inherit;
  //   transform: rotate(180deg);
  //   background-color: #fff;
  //   mask: url('../assets/images/driehoek.svg');
  //   mask-mode: alpha;
  // }
  // &:after {
  //   content: "";
  //   height: 100%;
  //   box-sizing: border-box;
  //   background-color: inherit;
  //   aspect-ratio: 1 / 1;
  //   /* margin-left: -50%; */
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   border-left: inherit;
  //   // transform: rotate(180deg);
  //   background-color: #fff;
  //   mask: url('../assets/images/driehoek.svg');
  //   mask-mode: alpha;
  // }
  a {
    // transform: skew(45deg);
    display: inline-block;
    color: #fff;
    border-left: 1px solid #333;
    // margin: 0.5em;
    // padding: 0 0.5em;
    &:first-of-type {
      border-left: none;
      // padding: 0 0.5em;
    }
  }
  @media screen and (min-width: 1000px) {
    padding: 10px 1.5em;
    transform: skew(-45deg);
    a {
      transform: skew(45deg);
    }
  }
}