/* ================
// html, universal, & body
// ============= */

html {
  box-sizing: border-box;
  font-family: $sansserif;
  color: $bodycolor;
}

// *,
// *:before,
// *:after {
//   box-sizing: inherit;
// }

body {
  font-size: 11px;
  line-height: 16px;
  @media screen and (min-width: 545px) {
    font-size: 14px;
  }
}
