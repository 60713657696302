$primary-colour: #7F408E;

$sansserif: 'Open Sans', sans-serif;
$serif: 'Playfair Display', serif;

$bodysize: 16;
$bodyline: 24;
$bodycolor: #222;

$h1size: 40;
$h1line: 50;

$h2size: 35;
$h2line: 45;

$h3size: 30;
$h3line: 40;

$h4size: 25;
$h4line: 35;

$h5size: 20;
$h5line: 30;

$h6size: 16;
$h6line: 22;

$boundary: 1400;

$xs: 'screen and (min-width:' + (300px) + ')';
$sm: 'screen and (min-width:' + (500px) + ')';
$md: 'screen and (min-width:' + (700px) + ')';
$lg: 'screen and (min-width:' + (900px) + ')';
$xl: 'screen and (min-width:' + (1100px) + ')';
$xxl: 'screen and (min-width:' + (1300px) + ')';

$max-xs: 'screen and (min-width:' + (299px) + ')';
$max-sm: 'screen and (min-width:' + (499px) + ')';
$max-md: 'screen and (min-width:' + (699px) + ')';
$max-lg: 'screen and (min-width:' + (899px) + ')';
$max-xl: 'screen and (min-width:' + (1099px) + ')';
$max-xxl: 'screen and (min-width:' + (1299px) + ')';
