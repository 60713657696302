:root {
  --f-spinner-width: 36px;
  --f-spinner-height: 36px;
  --f-spinner-color-1: #0000001a;
  --f-spinner-color-2: #11181ccc;
  --f-spinner-stroke: 2.75;
}

.f-spinner {
  width: var(--f-spinner-width);
  height: var(--f-spinner-height);
  margin: auto;
  padding: 0;
}

.f-spinner svg {
  width: 100%;
  height: 100%;
  vertical-align: top;
  animation: 2s linear infinite f-spinner-rotate;
}

.f-spinner svg * {
  stroke-width: var(--f-spinner-stroke);
  fill: none;
}

.f-spinner svg :first-child {
  stroke: var(--f-spinner-color-1);
}

.f-spinner svg :last-child {
  stroke: var(--f-spinner-color-2);
  animation: 2s ease-in-out infinite f-spinner-dash;
}

@keyframes f-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes f-spinner-dash {
  0% {
    stroke-dasharray: 1 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -124px;
  }
}

.f-panzoom {
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.f-panzoom.is-draggable {
  cursor: move;
  cursor: grab;
}

.f-panzoom.can-zoom_in {
  cursor: zoom-in;
}

.f-panzoom.can-zoom_out {
  cursor: zoom-out;
}

.f-panzoom.is-dragging {
  cursor: move;
  cursor: grabbing;
}

.f-panzoom.in-fullscreen {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: none !important;
  max-height: none !important;
  aspect-ratio: unset !important;
  margin: 0 !important;
}

.f-panzoom__content {
  max-width: 100%;
  max-height: 100%;
  min-height: 0;
  object-fit: contain;
  transform-origin: center;
  -webkit-user-select: none;
  user-select: none;
  margin: auto;
  transition: none;
  display: block;
  position: relative;
  transform: translate3d(0, 0, 0)scale(1)rotate(0)skew(0deg);
}

.is-loading .f-panzoom__content {
  display: none;
}

.is-scaling .f-panzoom__content {
  filter: blur();
  will-change: transform, width, height;
  backface-visibility: hidden;
}

picture.f-panzoom__content > img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.f-panzoom__content:not(:last-child) {
  margin-bottom: 0;
}

.f-panzoom__viewport {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 1px;
  margin: auto;
  position: relative;
}

.f-panzoom__viewport:not(:last-child) {
  margin-bottom: 0;
}

.f-panzoom__caption:not(:first-child) {
  margin-bottom: auto;
}

html.with-panzoom-in-fullscreen {
  overflow: hidden;
}

:root {
  --f-button-width: 40px;
  --f-button-height: 40px;
  --f-button-border: 0;
  --f-button-border-radius: 0;
  --f-button-color: #374151;
  --f-button-bg: #f8f8f8;
  --f-button-hover-bg: #e0e0e0;
  --f-button-active-bg: #d0d0d0;
  --f-button-shadow: none;
  --f-button-transition: all .15s ease;
  --f-button-transform: none;
  --f-button-svg-width: 20px;
  --f-button-svg-height: 20px;
  --f-button-svg-stroke-width: 1.5;
  --f-button-svg-fill: none;
  --f-button-svg-filter: none;
  --f-button-svg-disabled-opacity: .65;
}

.f-button {
  box-sizing: content-box;
  width: var(--f-button-width);
  height: var(--f-button-height);
  border: var(--f-button-border);
  border-radius: var(--f-button-border-radius);
  color: var(--f-button-color);
  background: var(--f-button-bg);
  box-shadow: var(--f-button-shadow);
  pointer-events: all;
  cursor: pointer;
  transition: var(--f-button-transition);
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

@media (hover: hover) {
  .f-button:hover:not([disabled]) {
    color: var(--f-button-hover-color);
    background-color: var(--f-button-hover-bg);
  }
}

.f-button:active:not([disabled]) {
  background-color: var(--f-button-active-bg);
}

.f-button:focus:not(:focus-visible) {
  outline: none;
}

.f-button:focus-visible {
  box-shadow: inset 0 0 0 var(--f-button-outline, 2px) var(--f-button-outline-color, var(--f-button-color));
  outline: none;
}

.f-button svg {
  width: var(--f-button-svg-width);
  height: var(--f-button-svg-height);
  fill: var(--f-button-svg-fill);
  stroke: currentColor;
  stroke-width: var(--f-button-svg-stroke-width);
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: var(--f-button-transform);
  filter: var(--f-button-svg-filter);
  pointer-events: none;
  transition: opacity .15s;
}

.f-button[disabled] {
  cursor: default;
}

.f-button[disabled] svg {
  opacity: var(--f-button-svg-disabled-opacity);
}

.f-panzoom__toolbar {
  opacity: 1;
  z-index: 1;
  flex-wrap: wrap;
  justify-content: center;
  margin: 4px;
  padding: 0;
  transition: opacity .15s;
  display: flex;
}

.is-idle .panzoom__toolbar {
  opacity: 0;
}

[data-panzoom-action="toggleFS"] g:first-child {
  display: flex;
}

[data-panzoom-action="toggleFS"] g:last-child, .in-fullscreen [data-panzoom-action="toggleFS"] g:first-child {
  display: none;
}

.in-fullscreen [data-panzoom-action="toggleFS"] g:last-child {
  display: flex;
}

.f-panzoom {
  --f-panzoom-pin-width: 24px;
  --f-panzoom-pin-height: 24px;
  --f-panzoom-pin-color: red;
}

[data-panzoom-pin] {
  width: 0;
  height: 0;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
}

.is-loading [data-panzoom-pin] {
  display: none;
}

[data-panzoom-pin] > * {
  width: var(--f-panzoom-pin-width, 24px);
  height: var(--f-panzoom-pin-height, 24px);
  justify-content: center;
  align-items: flex-end;
  display: flex;
  transform: translate(-50%, -100%);
}

[data-panzoom-pin] svg {
  width: 100%;
  height: 100%;
  fill: var(--f-panzoom-pin-color);
  pointer-events: none;
}

:root {
  --f-spinner-width: 36px;
  --f-spinner-height: 36px;
  --f-spinner-color-1: #0000001a;
  --f-spinner-color-2: #11181ccc;
  --f-spinner-stroke: 2.75;
}

.f-spinner {
  width: var(--f-spinner-width);
  height: var(--f-spinner-height);
  margin: auto;
  padding: 0;
}

.f-spinner svg {
  width: 100%;
  height: 100%;
  vertical-align: top;
  animation: 2s linear infinite f-spinner-rotate;
}

.f-spinner svg * {
  stroke-width: var(--f-spinner-stroke);
  fill: none;
}

.f-spinner svg :first-child {
  stroke: var(--f-spinner-color-1);
}

.f-spinner svg :last-child {
  stroke: var(--f-spinner-color-2);
  animation: 2s ease-in-out infinite f-spinner-dash;
}

.f-zoomInUp {
  animation: .2s ease-out .1s both f-zoomInUp;
}

.f-zoomOutDown {
  animation: .2s ease-out both f-zoomOutDown;
}

@keyframes f-zoomInUp {
  from {
    opacity: 0;
    transform: scale(.975)translate3d(0, 16px, 0);
  }

  to {
    opacity: 1;
    transform: scale(1)translate3d(0, 0, 0);
  }
}

@keyframes f-zoomOutDown {
  to {
    opacity: 0;
    transform: scale(.975)translate3d(0, 16px, 0);
  }
}

.f-throwOutUp {
  animation: .175s ease-out both f-throwOutUp;
}

.f-throwOutDown {
  animation: .175s ease-out both f-throwOutDown;
}

@keyframes f-throwOutUp {
  to {
    opacity: 0;
    transform: translate3d(0, -150px, 0);
  }
}

@keyframes f-throwOutDown {
  to {
    opacity: 0;
    transform: translate3d(0, 150px, 0);
  }
}

.f-fadeIn {
  z-index: 2;
  animation: .2s both f-fadeIn;
}

.f-fadeOut {
  z-index: 1;
  animation: .2s both f-fadeOut;
}

@keyframes f-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes f-fadeOut {
  100% {
    opacity: 0;
  }
}

.f-fadeSlowIn {
  z-index: 2;
  animation: .5s both f-fadeSlowIn;
}

.f-fadeSlowOut {
  z-index: 1;
  animation: .5s both f-fadeSlowOut;
}

@keyframes f-fadeSlowIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes f-fadeSlowOut {
  100% {
    opacity: 0;
  }
}

.f-fadeFastIn {
  z-index: 2;
  animation: .2s ease-out both f-fadeFastIn;
}

.f-fadeFastOut {
  z-index: 2;
  animation: .2s ease-out both f-fadeFastOut;
}

@keyframes f-fadeFastIn {
  0% {
    opacity: .75;
  }

  100% {
    opacity: 1;
  }
}

@keyframes f-fadeFastOut {
  100% {
    opacity: 0;
  }
}

.f-crossfadeIn {
  z-index: 2;
  animation: .2s ease-out both f-crossfadeIn;
}

.f-crossfadeOut {
  z-index: 1;
  animation: .1s linear .1s both f-crossfadeOut;
}

@keyframes f-crossfadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes f-crossfadeOut {
  100% {
    opacity: 0;
  }
}

.f-slideIn.from-next {
  animation: .85s cubic-bezier(.16, 1, .3, 1) f-slideInNext;
}

.f-slideIn.from-prev {
  animation: .85s cubic-bezier(.16, 1, .3, 1) f-slideInPrev;
}

.f-slideOut.to-next {
  animation: .85s cubic-bezier(.16, 1, .3, 1) f-slideOutNext;
}

.f-slideOut.to-prev {
  animation: .85s cubic-bezier(.16, 1, .3, 1) f-slideOutPrev;
}

@keyframes f-slideInPrev {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes f-slideInNext {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes f-slideOutNext {
  100% {
    transform: translateX(-100%);
  }
}

@keyframes f-slideOutPrev {
  100% {
    transform: translateX(100%);
  }
}

.f-classicIn.from-next {
  z-index: 2;
  animation: .85s cubic-bezier(.16, 1, .3, 1) f-classicInNext;
}

.f-classicIn.from-prev {
  z-index: 2;
  animation: .85s cubic-bezier(.16, 1, .3, 1) f-classicInPrev;
}

.f-classicOut.to-next {
  z-index: 1;
  animation: .85s cubic-bezier(.16, 1, .3, 1) f-classicOutNext;
}

.f-classicOut.to-prev {
  z-index: 1;
  animation: .85s cubic-bezier(.16, 1, .3, 1) f-classicOutPrev;
}

@keyframes f-classicInNext {
  0% {
    opacity: 0;
    transform: translateX(-75px);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes f-classicInPrev {
  0% {
    opacity: 0;
    transform: translateX(75px);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes f-classicOutNext {
  100% {
    opacity: 0;
    transform: translateX(-75px);
  }
}

@keyframes f-classicOutPrev {
  100% {
    opacity: 0;
    transform: translateX(75px);
  }
}

:root {
  --f-button-width: 40px;
  --f-button-height: 40px;
  --f-button-border: 0;
  --f-button-border-radius: 0;
  --f-button-color: #374151;
  --f-button-bg: #f8f8f8;
  --f-button-hover-bg: #e0e0e0;
  --f-button-active-bg: #d0d0d0;
  --f-button-shadow: none;
  --f-button-transition: all .15s ease;
  --f-button-transform: none;
  --f-button-svg-width: 20px;
  --f-button-svg-height: 20px;
  --f-button-svg-stroke-width: 1.5;
  --f-button-svg-fill: none;
  --f-button-svg-filter: none;
  --f-button-svg-disabled-opacity: .65;
}

.f-button {
  box-sizing: content-box;
  width: var(--f-button-width);
  height: var(--f-button-height);
  border: var(--f-button-border);
  border-radius: var(--f-button-border-radius);
  color: var(--f-button-color);
  background: var(--f-button-bg);
  box-shadow: var(--f-button-shadow);
  pointer-events: all;
  cursor: pointer;
  transition: var(--f-button-transition);
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

@media (hover: hover) {
  .f-button:hover:not([disabled]) {
    color: var(--f-button-hover-color);
    background-color: var(--f-button-hover-bg);
  }
}

.f-button:active:not([disabled]) {
  background-color: var(--f-button-active-bg);
}

.f-button:focus:not(:focus-visible) {
  outline: none;
}

.f-button:focus-visible {
  box-shadow: inset 0 0 0 var(--f-button-outline, 2px) var(--f-button-outline-color, var(--f-button-color));
  outline: none;
}

.f-button svg {
  width: var(--f-button-svg-width);
  height: var(--f-button-svg-height);
  fill: var(--f-button-svg-fill);
  stroke: currentColor;
  stroke-width: var(--f-button-svg-stroke-width);
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: var(--f-button-transform);
  filter: var(--f-button-svg-filter);
  pointer-events: none;
  transition: opacity .15s;
}

.f-button[disabled] {
  cursor: default;
}

.f-button[disabled] svg {
  opacity: var(--f-button-svg-disabled-opacity);
}

.f-carousel__nav .f-button.is-prev, .f-carousel__nav .f-button.is-next, .fancybox__nav .f-button.is-prev, .fancybox__nav .f-button.is-next {
  z-index: 1;
  position: absolute;
}

.is-horizontal .f-carousel__nav .f-button.is-prev, .is-horizontal .f-carousel__nav .f-button.is-next, .is-horizontal .fancybox__nav .f-button.is-prev, .is-horizontal .fancybox__nav .f-button.is-next {
  top: 50%;
  transform: translateY(-50%);
}

.is-horizontal .f-carousel__nav .f-button.is-prev, .is-horizontal .fancybox__nav .f-button.is-prev {
  left: var(--f-button-prev-pos);
}

.is-horizontal .f-carousel__nav .f-button.is-next, .is-horizontal .fancybox__nav .f-button.is-next {
  right: var(--f-button-next-pos);
}

.is-horizontal.is-rtl .f-carousel__nav .f-button.is-prev, .is-horizontal.is-rtl .fancybox__nav .f-button.is-prev {
  left: auto;
  right: var(--f-button-next-pos);
}

.is-horizontal.is-rtl .f-carousel__nav .f-button.is-next, .is-horizontal.is-rtl .fancybox__nav .f-button.is-next {
  right: auto;
  left: var(--f-button-prev-pos);
}

.is-vertical .f-carousel__nav .f-button.is-prev, .is-vertical .f-carousel__nav .f-button.is-next, .is-vertical .fancybox__nav .f-button.is-prev, .is-vertical .fancybox__nav .f-button.is-next {
  top: auto;
  left: 50%;
  transform: translateX(-50%);
}

.is-vertical .f-carousel__nav .f-button.is-prev, .is-vertical .fancybox__nav .f-button.is-prev {
  top: var(--f-button-next-pos);
}

.is-vertical .f-carousel__nav .f-button.is-next, .is-vertical .fancybox__nav .f-button.is-next {
  bottom: var(--f-button-next-pos);
}

.is-vertical .f-carousel__nav .f-button.is-prev svg, .is-vertical .f-carousel__nav .f-button.is-next svg, .is-vertical .fancybox__nav .f-button.is-prev svg, .is-vertical .fancybox__nav .f-button.is-next svg {
  transform: rotate(90deg);
}

html.with-fancybox {
  width: auto;
  scroll-behavior: auto;
  overflow: visible;
}

html.with-fancybox body {
  touch-action: none;
}

html.with-fancybox body.hide-scrollbar {
  width: auto;
  margin-right: calc(var(--fancybox-body-margin, 0px)  + var(--fancybox-scrollbar-compensate, 0px));
  overscroll-behavior-y: none;
  overflow: hidden !important;
}

.fancybox__container {
  --fancybox-color: #dbdbdb;
  --fancybox-hover-color: #fff;
  --fancybox-bg: #18181bfa;
  --fancybox-slide-gap: 10px;
  --f-spinner-width: 50px;
  --f-spinner-height: 50px;
  --f-spinner-color-1: #ffffff1a;
  --f-spinner-color-2: #bbb;
  --f-spinner-stroke: 3.65;
  direction: ltr;
  box-sizing: border-box;
  color: #f8f8f8;
  -webkit-tap-highlight-color: #0000;
  z-index: 1050;
  transform-origin: 0 0;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  overscroll-behavior-y: contain;
  outline: none;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
  position: fixed;
  inset: 0;
  overflow: visible;
}

.fancybox__container *, .fancybox__container :before, .fancybox__container :after {
  box-sizing: inherit;
}

.fancybox__backdrop {
  z-index: -1;
  background: var(--fancybox-bg);
  opacity: var(--fancybox-opacity, 1);
  will-change: opacity;
  position: fixed;
  inset: 0;
}

.fancybox__carousel {
  box-sizing: border-box;
  min-height: 0;
  z-index: 10;
  flex: 1;
  position: relative;
  overflow-x: clip;
  overflow-y: visible;
}

.fancybox__viewport {
  width: 100%;
  height: 100%;
}

.fancybox__track {
  height: 100%;
  margin: 0 auto;
  display: flex;
}

.fancybox__slide {
  width: 100%;
  height: 100%;
  margin: 0 var(--fancybox-slide-gap) 0 0;
  backface-visibility: hidden;
  flex-direction: column;
  flex: none;
  align-items: center;
  padding: 4px;
  display: flex;
  position: relative;
  overflow: auto;
  transform: translate3d(0, 0, 0);
}

.fancybox__container:not(.is-compact) .fancybox__slide.has-close-btn {
  padding-top: 40px;
}

.fancybox__slide.has-iframe, .fancybox__slide.has-video, .fancybox__slide.has-html5video, .fancybox__slide.has-image {
  overflow: hidden;
}

.fancybox__slide.has-image.is-animating, .fancybox__slide.has-image.is-selected {
  overflow: visible;
}

.fancybox__slide:before, .fancybox__slide:after {
  content: "";
  flex: 0 0 0;
  margin: auto;
}

.fancybox__content {
  max-width: 100%;
  color: var(--fancybox-content-color, #374151);
  background: var(--fancybox-content-bg, #fff);
  cursor: default;
  z-index: 20;
  border-radius: 0;
  flex-direction: column;
  align-self: center;
  margin: 0;
  padding: 2rem;
  display: flex;
  position: relative;
}

.is-loading .fancybox__content {
  opacity: 0;
}

.is-draggable .fancybox__content {
  cursor: move;
  cursor: grab;
}

.can-zoom_in .fancybox__content {
  cursor: zoom-in;
}

.can-zoom_out .fancybox__content {
  cursor: zoom-out;
}

.is-dragging .fancybox__content {
  cursor: move;
  cursor: grabbing;
}

.fancybox__content [data-selectable], .fancybox__content [contenteditable] {
  cursor: auto;
}

.fancybox__slide.has-image > .fancybox__content {
  min-height: 1px;
  backface-visibility: hidden;
  background: center / contain no-repeat;
  padding: 0;
  transition: none;
  transform: translate3d(0, 0, 0);
}

.fancybox__slide.has-image > .fancybox__content > picture > img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.is-zooming-in .fancybox__viewport:not(.is-dragging) .fancybox__slide:not(.is-selected) .fancybox__content, .is-zooming-out .fancybox__slide:not(.is-selected) .fancybox__content {
  visibility: hidden;
}

.is-animating .fancybox__content, .is-dragging .fancybox__content {
  filter: blur();
  will-change: transform, width, height;
}

.fancybox-image {
  width: 100%;
  height: 100%;
  min-height: 0;
  object-fit: contain;
  -webkit-user-select: none;
  user-select: none;
  margin: auto;
  display: block;
}

.fancybox__caption {
  max-width: 100%;
  overflow-wrap: anywhere;
  color: var(--fancybox-color, currentColor);
  opacity: var(--fancybox-opacity, 1);
  cursor: auto;
  visibility: visible;
  flex-shrink: 0;
  align-self: center;
  margin: 0;
  padding: 14px 0 4px;
  line-height: 1.375;
}

.is-loading .fancybox__caption, .is-closing .fancybox__caption {
  opacity: 0;
  visibility: hidden;
}

.is-compact .fancybox__caption {
  padding-bottom: 0;
}

.f-button.is-close-btn {
  --f-button-svg-stroke-width: 2;
  z-index: 40;
  position: absolute;
  top: 0;
  right: 8px;
}

.fancybox__content > .f-button.is-close-btn {
  --f-button-width: 34px;
  --f-button-height: 34px;
  --f-button-border-radius: 4px;
  --f-button-color: var(--fancybox-color, #fff);
  --f-button-hover-color: var(--fancybox-color, #fff);
  --f-button-bg: transparent;
  --f-button-hover-bg: transparent;
  --f-button-active-bg: transparent;
  --f-button-svg-width: 22px;
  --f-button-svg-height: 22px;
  opacity: .75;
  position: absolute;
  top: -38px;
  right: 0;
}

.is-loading .fancybox__content > .f-button.is-close-btn, .is-zooming-out .fancybox__content > .f-button.is-close-btn {
  visibility: hidden;
}

.fancybox__content > .f-button.is-close-btn:hover {
  opacity: 1;
}

.fancybox__footer {
  margin: 0;
  padding: 0;
  position: relative;
}

.fancybox__footer .fancybox__caption {
  width: 100%;
  opacity: var(--fancybox-opacity, 1);
  padding: 24px;
  transition: all .25s;
}

.is-compact .fancybox__footer {
  z-index: 20;
  background: #18181b80;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.is-compact .fancybox__footer .fancybox__caption {
  padding: 12px;
}

.is-compact .fancybox__content > .f-button.is-close-btn {
  --f-button-border-radius: 50%;
  --f-button-color: #fff;
  --f-button-hover-color: #fff;
  --f-button-outline-color: #000;
  --f-button-bg: #0009;
  --f-button-active-bg: #0009;
  --f-button-hover-bg: #0009;
  --f-button-svg-width: 18px;
  --f-button-svg-height: 18px;
  --f-button-svg-filter: none;
  top: 5px;
  right: 5px;
}

.fancybox__nav {
  --f-button-width: 50px;
  --f-button-height: 50px;
  --f-button-border: 0;
  --f-button-border-radius: 50%;
  --f-button-color: var(--fancybox-color);
  --f-button-hover-color: var(--fancybox-hover-color);
  --f-button-bg: transparent;
  --f-button-hover-bg: #18181b4d;
  --f-button-active-bg: #18181b80;
  --f-button-shadow: none;
  --f-button-transition: all .15s ease;
  --f-button-transform: none;
  --f-button-svg-width: 26px;
  --f-button-svg-height: 26px;
  --f-button-svg-stroke-width: 2.5;
  --f-button-svg-fill: none;
  --f-button-svg-filter: drop-shadow(1px 1px 1px #18181b80);
  --f-button-svg-disabled-opacity: .65;
  --f-button-next-pos: 1rem;
  --f-button-prev-pos: 1rem;
  opacity: var(--fancybox-opacity, 1);
}

.fancybox__nav .f-button:before {
  content: "";
  z-index: 1;
  position: absolute;
  inset: -30px -20px;
}

.is-idle .fancybox__nav {
  animation: .15s ease-out both f-fadeOut;
}

.is-idle.is-compact .fancybox__footer {
  pointer-events: none;
  animation: .15s ease-out both f-fadeOut;
}

.fancybox__slide > .f-spinner {
  margin: var(--f-spinner-top, calc(var(--f-spinner-width) * -.5)) 0 0 var(--f-spinner-left, calc(var(--f-spinner-height) * -.5));
  z-index: 30;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
}

.fancybox-protected {
  z-index: 40;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  inset: 0;
}

.fancybox-ghost {
  width: 100%;
  height: 100%;
  min-height: 0;
  object-fit: contain;
  z-index: 40;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.fancybox-focus-guard {
  opacity: 0;
  pointer-events: none;
  outline: none;
  position: fixed;
}

.fancybox__container:not([aria-hidden]) {
  opacity: 0;
}

.fancybox__container.is-animated[aria-hidden="false"] > :not(.fancybox__backdrop, .fancybox__carousel), .fancybox__container.is-animated[aria-hidden="false"] .fancybox__carousel > :not(.fancybox__viewport), .fancybox__container.is-animated[aria-hidden="false"] .fancybox__slide > :not(.fancybox__content) {
  animation: .25s .1s backwards f-fadeIn;
}

.fancybox__container.is-animated[aria-hidden="false"] .fancybox__backdrop {
  animation: .35s backwards f-fadeIn;
}

.fancybox__container.is-animated[aria-hidden="true"] > :not(.fancybox__backdrop, .fancybox__carousel), .fancybox__container.is-animated[aria-hidden="true"] .fancybox__carousel > :not(.fancybox__viewport), .fancybox__container.is-animated[aria-hidden="true"] .fancybox__slide > :not(.fancybox__content) {
  animation: .15s forwards f-fadeOut;
}

.fancybox__container.is-animated[aria-hidden="true"] .fancybox__backdrop {
  animation: .35s forwards f-fadeOut;
}

.has-iframe .fancybox__content, .has-map .fancybox__content, .has-pdf .fancybox__content, .has-youtube .fancybox__content, .has-vimeo .fancybox__content, .has-html5video .fancybox__content {
  max-width: 100%;
  min-height: 1px;
  flex-shrink: 1;
  overflow: visible;
}

.has-iframe .fancybox__content, .has-map .fancybox__content, .has-pdf .fancybox__content {
  width: 100%;
  height: 100%;
}

.fancybox__container:not(.is-compact) .has-iframe .fancybox__content, .fancybox__container:not(.is-compact) .has-map .fancybox__content, .fancybox__container:not(.is-compact) .has-pdf .fancybox__content {
  width: calc(100% - 120px);
  height: 90%;
}

.has-youtube .fancybox__content, .has-vimeo .fancybox__content, .has-html5video .fancybox__content {
  width: 960px;
  height: 540px;
  max-width: 100%;
  max-height: 100%;
}

.has-map .fancybox__content, .has-pdf .fancybox__content, .has-youtube .fancybox__content, .has-vimeo .fancybox__content, .has-html5video .fancybox__content {
  color: #fff;
  background: #18181be6;
  padding: 0;
}

.has-map .fancybox__content {
  background: #e5e3df;
}

.fancybox__html5video, .fancybox__iframe {
  height: 100%;
  width: 100%;
  background: none;
  border: 0;
  display: block;
}

.fancybox-placeholder {
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  width: 1px !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.f-carousel__thumbs {
  --f-thumb-width: 96px;
  --f-thumb-height: 72px;
  --f-thumb-outline: 0;
  --f-thumb-outline-color: #5eb0ef;
  --f-thumb-opacity: 1;
  --f-thumb-hover-opacity: 1;
  --f-thumb-selected-opacity: 1;
  --f-thumb-border-radius: 2px;
  --f-thumb-offset: 0px;
  --f-button-next-pos: 0;
  --f-button-prev-pos: 0;
}

.f-carousel__thumbs.is-classic {
  --f-thumb-gap: 8px;
  --f-thumb-opacity: .5;
  --f-thumb-hover-opacity: 1;
  --f-thumb-selected-opacity: 1;
}

.f-carousel__thumbs.is-modern {
  --f-thumb-gap: 4px;
  --f-thumb-extra-gap: 20px;
  --f-thumb-clip-width: 46px;
}

.f-thumbs {
  -webkit-tap-highlight-color: #0000;
  -webkit-user-select: none;
  user-select: none;
  perspective: 1000px;
  flex: none;
  margin: 0;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.f-thumbs .f-spinner {
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: linear-gradient(#ebeff2, #e2e8f0);
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

.f-thumbs .f-spinner svg {
  display: none;
}

.f-thumbs.is-vertical {
  height: 100%;
}

.f-thumbs__viewport {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.f-thumbs__track {
  will-change: transform;
  display: flex;
}

.f-thumbs__slide {
  box-sizing: content-box;
  width: var(--f-thumb-width);
  min-width: var(--f-thumb-width);
  height: var(--f-thumb-height);
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  overflow: visible;
}

.f-thumbs__slide.is-loading img {
  opacity: 0;
}

.is-classic .f-thumbs__viewport {
  height: 100%;
}

.is-modern .f-thumbs__track {
  width: max-content;
}

.is-modern .f-thumbs__track:before {
  content: "";
  top: 0;
  bottom: 0;
  left: calc(var(--left, 0) * 1px);
  width: calc(100% - var(--width, 0) * 1px);
  cursor: pointer;
  position: absolute;
}

.is-modern .f-thumbs__slide {
  --clip-path: inset(0 calc((var(--f-thumb-width, 0)  - var(--f-thumb-clip-width, 0)) * .5 * (1 - var(--progress, 0))) round var(--f-thumb-border-radius, 0));
  transform: translate3d(calc(var(--shift, 0) * -1px), 0, 0);
  pointer-events: none;
  transition: none;
}

.is-modern .f-thumbs__slide:focus-within:not(.is-selected) {
  filter: drop-shadow(-1px 0px 0px var(--f-thumb-outline-color)) drop-shadow(2px 0px 0px var(--f-thumb-outline-color)) drop-shadow(0px -1px 0px var(--f-thumb-outline-color)) drop-shadow(0px 2px 0px var(--f-thumb-outline-color));
}

.is-modern .f-thumbs__slide > * {
  clip-path: var(--clip-path);
}

.is-modern.in-touch .f-thumbs__slide {
  filter: none;
}

.is-modern.is-resting .f-thumbs__slide, .is-modern.is-resting .f-thumbs__slide > * {
  transition: all .33s;
}

.f-thumbs__slide__button {
  appearance: none;
  width: 100%;
  height: 100%;
  border-radius: var(--f-thumb-border-radius);
  cursor: pointer;
  pointer-events: auto;
  touch-action: manipulation;
  opacity: var(--f-thumb-opacity);
  background: none;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0;
  transition: opacity .2s;
  position: relative;
  overflow: hidden;
}

.f-thumbs__slide__button:hover {
  opacity: var(--f-thumb-hover-opacity);
}

.f-thumbs__slide__button:focus:not(:focus-visible) {
  outline: none;
}

.f-thumbs__slide__button:focus-visible {
  opacity: var(--f-thumb-selected-opacity);
  outline: none;
}

.is-nav-selected .f-thumbs__slide__button {
  opacity: var(--f-thumb-selected-opacity);
}

.is-nav-selected .f-thumbs__slide__button:after {
  content: "";
  height: auto;
  border: var(--f-thumb-outline, 0) solid var(--f-thumb-outline-color, transparent);
  border-radius: var(--f-thumb-border-radius);
  z-index: 10;
  animation: .2s ease-out f-fadeIn;
  position: absolute;
  inset: 0;
}

.f-thumbs__slide__img {
  width: 100%;
  height: 100%;
  padding: var(--f-thumb-offset);
  box-sizing: border-box;
  pointer-events: none;
  object-fit: cover;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.f-thumbs.is-horizontal .f-thumbs__track {
  margin: 0 auto;
  padding: 8px 0 12px;
}

.f-thumbs.is-horizontal .f-thumbs__slide {
  margin: 0 var(--f-thumb-gap) 0 0;
}

.f-thumbs.is-vertical .f-thumbs__track {
  flex-wrap: wrap;
  margin: auto 0;
  padding: 0 8px;
}

.f-thumbs.is-vertical .f-thumbs__slide {
  margin: 0 0 var(--f-thumb-gap) 0;
}

.fancybox__thumbs {
  --f-thumb-width: 96px;
  --f-thumb-height: 72px;
  --f-thumb-border-radius: 2px;
  --f-thumb-outline: 2px;
  --f-thumb-outline-color: #ededed;
  opacity: var(--fancybox-opacity, 1);
  transition: max-height .35s cubic-bezier(.23, 1, .32, 1);
  position: relative;
}

.fancybox__thumbs.is-classic {
  --f-thumb-gap: 8px;
  --f-thumb-opacity: .5;
  --f-thumb-hover-opacity: 1;
}

.fancybox__thumbs.is-classic .f-spinner {
  background-image: linear-gradient(#ffffff1a, #ffffff0d);
}

.fancybox__thumbs.is-modern {
  --f-thumb-gap: 4px;
  --f-thumb-extra-gap: 20px;
  --f-thumb-clip-width: 46px;
  --f-thumb-opacity: 1;
  --f-thumb-hover-opacity: 1;
}

.fancybox__thumbs.is-modern .f-spinner {
  background-image: linear-gradient(#ffffff1a, #ffffff0d);
}

.fancybox__thumbs.is-horizontal {
  padding: 0 var(--f-thumb-gap);
}

.fancybox__thumbs.is-vertical {
  padding: var(--f-thumb-gap) 0;
}

.is-compact .fancybox__thumbs {
  --f-thumb-width: 64px;
  --f-thumb-clip-width: 32px;
  --f-thumb-height: 48px;
  --f-thumb-extra-gap: 10px;
}

.fancybox__thumbs.is-hidden {
  max-height: 0 !important;
}

.is-closing .fancybox__thumbs {
  transition: none !important;
}

.fancybox__toolbar {
  --f-progress-color: var(--fancybox-color, #fffffff0);
  --f-button-width: 46px;
  --f-button-height: 46px;
  --f-button-color: var(--fancybox-color);
  --f-button-hover-color: var(--fancybox-hover-color);
  --f-button-bg: #18181ba6;
  --f-button-hover-bg: #464649a6;
  --f-button-active-bg: #5a5a5da6;
  --f-button-border-radius: 0;
  --f-button-svg-width: 24px;
  --f-button-svg-height: 24px;
  --f-button-svg-stroke-width: 1.5;
  --f-button-svg-filter: drop-shadow(1px 1px 1px #18181b26);
  --f-button-svg-fill: none;
  --f-button-svg-disabled-opacity: .65;
  color: var(--fancybox-color, currentColor);
  opacity: var(--fancybox-opacity, 1);
  text-shadow: var(--fancybox-toolbar-text-shadow, 1px 1px 1px #00000080);
  pointer-events: none;
  z-index: 20;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI Adjusted, Segoe UI, Liberation Sans, sans-serif;
  display: flex;
}

.fancybox__toolbar :focus-visible {
  z-index: 1;
}

.fancybox__toolbar.is-absolute, .is-compact .fancybox__toolbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.is-idle .fancybox__toolbar {
  pointer-events: none;
  animation: .15s ease-out both f-fadeOut;
}

.fancybox__toolbar__column {
  flex-flow: wrap;
  align-content: flex-start;
  display: flex;
}

.fancybox__toolbar__column.is-left, .fancybox__toolbar__column.is-right {
  flex-grow: 1;
  flex-basis: 0;
}

.fancybox__toolbar__column.is-right {
  flex-wrap: nowrap;
  justify-content: flex-end;
  display: flex;
}

.fancybox__infobar {
  line-height: var(--f-button-height);
  text-align: center;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: subpixel-antialiased;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  padding: 0 5px;
  font-size: 17px;
}

.fancybox__infobar span {
  padding: 0 5px;
}

.fancybox__infobar:not(:first-child):not(:last-child) {
  background: var(--f-button-bg);
}

[data-fancybox-toggle-slideshow] {
  position: relative;
}

[data-fancybox-toggle-slideshow] .f-progress {
  height: 100%;
  opacity: .3;
}

[data-fancybox-toggle-slideshow] svg g:first-child {
  display: flex;
}

[data-fancybox-toggle-slideshow] svg g:last-child, .has-slideshow [data-fancybox-toggle-slideshow] svg g:first-child {
  display: none;
}

.has-slideshow [data-fancybox-toggle-slideshow] svg g:last-child, [data-fancybox-toggle-fullscreen] svg g:first-child {
  display: flex;
}

[data-fancybox-toggle-fullscreen] svg g:last-child, :fullscreen [data-fancybox-toggle-fullscreen] svg g:first-child {
  display: none;
}

:-webkit-full-screen [data-fancybox-toggle-fullscreen] svg g:last-child {
  display: flex;
}

:fullscreen [data-fancybox-toggle-fullscreen] svg g:last-child {
  display: flex;
}

.f-progress {
  height: 3px;
  transform-origin: 0;
  background: var(--f-progress-color, var(--f-carousel-theme-color, #0091ff));
  z-index: 30;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  transition-property: transform;
  transition-timing-function: linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: scaleX(0);
}

html {
  box-sizing: border-box;
  color: #222;
  font-family: Open Sans, sans-serif;
}

body {
  font-size: 11px;
  line-height: 16px;
}

@media screen and (min-width: 545px) {
  body {
    font-size: 14px;
  }
}

.button {
  vertical-align: top;
  margin: 0 em(10) em(10) 0;
  padding: em(10) em(20);
  cursor: pointer;
  text-align: center;
  border: 0;
  display: inline-block;
}

.button[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}

.section {
  padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

.compartment {
  max-width: 87.5rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

#header {
  height: 50px;
  width: 100%;
  z-index: 10;
  overflow-y: auto;
}

@media screen and (min-width: 545px) {
  #header {
    height: 64px;
    background: linear-gradient(to right, #fff 0% 50%, #fff 50%, #9e51b0 51% 100%);
  }
}

#mapsection.visible #header {
  position: relative;
}

#header .compartment {
  height: 100%;
  max-height: 100%;
  background-color: #7f408e;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 0;
  display: flex;
}

body.show-tables #header .compartment {
  justify-content: space-between;
}

@media screen and (min-width: 375px) {
  #header .compartment {
    justify-content: space-between;
  }
}

#header h1 {
  text-align: right;
  flex-grow: 2;
  font-size: 1.5em;
  font-weight: normal;
}

@media screen and (min-width: 981px) {
  #header h1 {
    font-size: 2em;
  }
}

#header .header-title {
  text-align: right;
}

#header a.back {
  margin-right: 10px;
}

div.toggle {
  box-sizing: border-box;
  flex-flow: row;
  display: flex;
}

div.toggle a {
  pointer-events: all;
  cursor: pointer;
  height: 2em;
  border-left: 1px solid #fff;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: .5em 1em;
  font-size: 1.2em;
  display: flex;
}

div.toggle a img, div.toggle a svg {
  cursor: pointer;
  height: 18px;
  font-size: 18px;
}

@media screen and (min-width: 445px) {
  div.toggle a img, div.toggle a svg {
    height: 22px;
    font-size: 22px;
  }
}

div.toggle a .button_container {
  width: 1.2em;
  height: 1.2em;
  min-height: 1em;
  cursor: pointer;
  z-index: 100;
  -o-transition: opacity .25s ease, top .5s ease;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 1.5rem;
  transition: opacity .25s, top .5s;
  display: flex;
  position: relative;
  top: auto;
  right: auto;
}

div.toggle a .button_container span {
  height: .12em;
  width: 75%;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: .06em;
  transition: transform .3s, opacity .3s, width .3s;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
}

div.toggle a .button_container span:before {
  content: "";
  height: .12em;
  width: .12em;
  background-color: #fff;
  border-radius: .06em;
  margin-left: -25%;
  display: block;
}

div.toggle a.active .button_container span {
  width: 100%;
}

div.toggle a.active .button_container span:before {
  opacity: 0;
}

div.toggle a.active .button_container span.top {
  transform-origin: 50%;
  transform: translate(0, .5em)rotate(45deg);
}

div.toggle a.active .button_container span.middle {
  opacity: 0;
}

div.toggle a.active .button_container span.bottom {
  transform-origin: 50%;
  transform: translate(0, -.55em)rotate(-45deg);
}

@media screen and (max-width: 445px) {
  body.show-tables div.toggle a {
    border-left: none;
  }
}

@media screen and (min-width: 545px) {
  div.toggle a {
    font-size: 1em;
  }
}

#logo {
  opacity: 0;
  height: 50px;
  padding: 0 4em 0 calc(env(safe-area-inset-left, 0)  + 10px);
  max-height: 100%;
  background-color: #fff;
  flex: 0 200px;
  transition: left .2s, opacity .3s;
  display: block;
  position: absolute;
  top: 0;
  left: -100%;
  transform: skew(-45deg)translate(-3em);
}

body.show-tables #logo {
  opacity: 1;
  position: relative;
  left: 0;
}

@media screen and (min-width: 375px) {
  #logo {
    opacity: 1;
    height: 100%;
    display: block;
    position: relative;
    left: 0;
  }
}

#logo a {
  box-sizing: border-box;
  height: 100%;
  max-width: 100%;
  display: block;
  transform: skew(45deg)translate(3em);
}

#logo img {
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  padding: 10px 0;
  display: block;
}

@media screen and (min-width: 545px) {
  #logo {
    flex: 0 365px;
  }
}

@media screen and (min-height: 600px) {
  #logo img {
    max-height: 100%;
    padding: 10px 0;
  }
}

.map-selectors, .info-selectors {
  height: 100%;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.info-selectors {
  padding: 0 0 env(safe-area-inset-right, 0) 0;
  flex: 0 auto;
  position: relative;
}

.info-selectors:before {
  content: "";
  height: 100%;
  box-sizing: border-box;
  background-color: inherit;
  aspect-ratio: 1 / 1;
  border-right: inherit;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  transform: skew(-45deg)translate(-50%);
}

.info-selectors #show-info, .info-selectors .toggle {
  z-index: 2;
  -webkit-user-select: none;
  user-select: none;
}

@media screen and (min-width: 545px) {
  .info-selectors {
    background-color: #7f408e;
  }
}

@media screen and (min-width: 650px) {
  .info-selectors {
    background-color: #9e51b0;
  }
}

#show-info {
  justify-content: flex-end;
  align-items: center;
  padding: .5em 1em;
  transition: border .3s;
  display: flex;
}

#show-info svg, #show-info img {
  cursor: pointer;
  height: 2em;
}

#show-info svg path.letter, #show-info img path.letter {
  fill: #fff;
}

@media screen and (max-width: 375px) {
  body.show-tables #show-info {
    display: none;
  }
}

#tables {
  flex-flow: column;
  display: flex;
}

#tables h3 {
  color: #fff;
  background-color: #a862b8;
  margin: 20px 10px 10px;
  padding: 1em .5em;
}

#tables .button {
  text-align: left;
}

#tables .compartment {
  width: 100%;
  max-width: 1000px;
  flex-flow: wrap;
  justify-content: space-between;
  display: flex;
}

#tables .compartment > div {
  box-sizing: border-box;
  flex: 50%;
}

#tables .compartment div.crisistypen {
  flex-basis: 100%;
}

@media screen and (min-width: 768px) {
  #tables .compartment div.crisistypen {
    flex-basis: 66%;
  }
}

#tables .compartment div.crisistypen h3 {
  background-color: #7f408e;
}

#tables .compartment div.crisistypen .tables-inner {
  padding-bottom: 2em;
}

#tables .compartment div.crisistypen .tables-inner table tr {
  background-color: #f6eff8;
}

#tables .compartment div.crisistypen .tables-inner table th {
  background-color: #d9e3f1;
}

#tables .compartment div.gemeenten {
  flex-basis: 100%;
}

@media screen and (min-width: 768px) {
  #tables .compartment div.gemeenten {
    flex-basis: 33%;
  }
}

#tables .compartment div.gemeenten h3 {
  background-color: #f7921e;
}

#tables .compartment div.gemeenten .tables-inner table tr {
  background-color: #fdf5f0;
}

#tables .compartment div.aanjagers {
  flex-basis: 100%;
}

@media screen and (min-width: 768px) {
  #tables .compartment div.aanjagers {
    flex-basis: 33%;
  }
}

#tables .compartment div.aanjagers h3 {
  background-color: #78b65b;
}

#tables .compartment div.aanjagers .tables-inner table tbody {
  flex-flow: wrap;
  place-content: flex-start;
  display: flex;
}

#tables .compartment div.aanjagers .tables-inner table tr {
  box-sizing: border-box;
  flex: 100%;
  padding: 0 10px;
}

@media screen and (min-width: 768px) {
  #tables .compartment div.aanjagers .tables-inner table tr {
    flex: 0 33.3%;
  }
}

#tables .compartment div.aanjagers .tables-inner table tr:nth-child(2n+1) {
  background-color: #e3efd9;
}

#tables .compartment div.aanjagers .tables-inner table tr:nth-child(2n) {
  background-color: #f8fbf6;
}

#tables .compartment div.tables-inner {
  flex-flow: wrap;
  place-content: stretch space-between;
  padding: 0 10px;
  display: flex;
}

#tables .compartment div.tables-inner > div {
  box-sizing: border-box;
  flex: 100%;
}

@media screen and (min-width: 768px) {
  #tables .compartment div.tables-inner > div {
    flex: 50%;
  }
}

#tables .compartment div.tables-inner table, #tables .compartment div.tables-inner th, #tables .compartment div.tables-inner td {
  border-collapse: collapse;
  border: none;
}

#tables .compartment div.tables-inner table {
  width: 100%;
  margin-top: 2em;
}

#tables .compartment div.tables-inner table:first-of-type {
  margin-top: 0;
}

#tables .compartment div.tables-inner table a {
  color: #000;
  text-decoration: none;
}

#tables .compartment div.tables-inner table a:hover {
  color: #000c;
}

#tables .compartment div.tables-inner table tr, #tables .compartment div.tables-inner table td {
  border: 0;
  margin: 0;
}

#tables .compartment div.tables-inner table td {
  padding: 1em .5em;
}

#tables .compartment div.tables-inner table th {
  text-align: left;
  padding: 1em .5em;
}

#tables .break {
  display: none;
}

#tables {
  max-height: 100%;
  background-color: #fff0;
  transition: bottom .3s, background-color .3s, height .3s;
}

body.js #tables {
  z-index: 10;
  width: 100%;
  max-height: calc(100% - 50px);
  height: calc(100% - 50px);
  background-color: #fff;
  position: absolute;
  bottom: 0;
  overflow: auto;
}

@media screen and (min-width: 545px) {
  body.js #tables {
    max-height: calc(100% - 64px);
    height: calc(100% - 64px);
  }
}

body.js #tables.hidden {
  width: 100%;
  height: 0;
  position: absolute;
  bottom: -100%;
}

#documents.compartment a, #footer.compartment a {
  color: #505050;
}

#documents.compartment .logo, #footer.compartment .logo {
  background-color: #fff;
  flex: 0 390px;
  position: relative;
}

#documents.compartment .logo img, #footer.compartment .logo img {
  height: 200px;
  width: 370px;
  max-height: 200px;
  max-width: 100%;
  object-fit: contain;
  padding: 10px;
  display: block;
}

#documents.compartment .documents, #footer.compartment .documents {
  background-color: #faf6fb;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  display: flex;
}

#documents.compartment .documents .document, #footer.compartment .documents .document {
  margin-right: 1em;
  line-height: 1.5em;
  display: block;
}

#documents.compartment .documents .document img, #footer.compartment .documents .document img {
  max-width: 100px;
  margin: 10px 10px 10px 0;
  padding-right: 10px;
  display: block;
}

#documents.compartment {
  flex-flow: column;
  align-content: flex-start;
  display: flex;
}

#documents.compartment > div {
  box-sizing: border-box;
  flex: auto;
  margin: 10px;
  padding: 10px;
}

#footer {
  margin-top: 1em;
}

#footer .footer {
  background-color: #7f408e;
  justify-content: flex-start;
  margin: 0 10px;
  padding: 10px;
}

#footer .footer a {
  color: #fff;
  border-left: 1px solid #333;
  display: inline-block;
}

#footer .footer a:first-of-type {
  border-left: none;
}

@media screen and (min-width: 1000px) {
  #footer .footer {
    padding: 10px 1.5em;
    transform: skew(-45deg);
  }

  #footer .footer a {
    transform: skew(45deg);
  }
}

.popup.content {
  padding: 1em;
  line-height: 1.5em;
}

.popup.content .content-layout {
  max-width: 800px;
  flex-flow: wrap;
  margin: 0 auto;
  display: flex;
}

.popup.content .content-layout .column-2-5 {
  flex: 1 0 40%;
  order: 2;
  padding: 20px;
}

@media screen and (min-width: 981px) {
  .popup.content .content-layout .column-2-5 {
    order: 0;
    padding: 20px 20px 20px 0;
  }
}

.popup.content .content-layout .column-2-5 img {
  min-width: 100px;
  max-width: 100%;
}

@media screen and (min-width: 981px) {
  .popup.content .content-layout .column-2-5 img {
    max-width: 250px;
  }
}

@media screen and (min-width: 768px) {
  .popup.content .content-layout .columns {
    columns: 2;
  }

  .popup.content .content-layout .columns p {
    margin-top: 0;
  }
}

.popup.content a.back {
  margin-top: 50px;
  display: block;
}

.popup.content .compartment {
  max-width: 800px;
}

.popup.content .compartment > .illustration {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.popup.content .compartment > .illustration input {
  opacity: 0;
  z-index: 1001;
  position: absolute;
  top: 10px;
}

.popup.content .compartment > .illustration .filter {
  color: #fff;
  background-color: #d40f2f;
  border-radius: 1em;
  padding: .5em 1em;
  line-height: 1em;
  text-decoration: none;
  display: block;
}

.popup.content .compartment > .illustration .filter.gemeenten {
  background-color: #f7921e;
}

@media screen and (min-width: 761px) {
  .popup.content .compartment > .illustration .filter {
    font-size: 14px;
  }
}

.popup.content .compartment > .illustration img {
  pointer-events: none;
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 981px) {
  .popup.content .compartment > .illustration img {
    width: auto;
    max-height: 70vh;
    max-width: 800px;
  }
}

.popup.content .compartment > .illustration .buttons {
  flex-flow: wrap;
  place-content: space-evenly center;
  align-items: center;
  padding-top: 1em;
  display: none;
}

.popup.content .compartment > .illustration .buttons .button {
  margin: 0 1em 1.5em 0;
  font-size: 12px;
  position: relative;
}

.popup.content h1 {
  border-bottom: 1px solid #dedede;
  margin: 0 0 .5em;
  padding: .2em 1rem .2em 0;
  font-size: 20px;
  font-weight: normal;
  line-height: 30px;
}

@media screen and (min-width: 761px) {
  .popup.content h1 {
    font-size: 40px;
    line-height: 50px;
  }
}

.popup.content h1 .break {
  display: none;
}

.popup.content h2.inicident-group {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 1rem;
  font-weight: normal;
  position: relative;
}

.popup.content h2.inicident-group:before {
  content: "";
  height: 100%;
  box-sizing: border-box;
  background-color: inherit;
  aspect-ratio: 1 / 1;
  border-right: inherit;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-99%);
  -webkit-mask: url("driehoek.373ff50c.svg");
  mask: url("driehoek.373ff50c.svg");
}

.popup.content h2.inicident-group:after {
  content: "";
  height: 100%;
  box-sizing: border-box;
  background-color: inherit;
  aspect-ratio: 1 / 1;
  border-left: inherit;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(99%)rotate(180deg);
  -webkit-mask: url("driehoek.373ff50c.svg");
  -webkit-mask-source-type: alpha;
  mask: url("driehoek.373ff50c.svg") alpha;
}

.popup.content h2.inicident-group.natuurlijke-omgeving {
  background-color: #d9e3f1;
}

.popup.content h2.inicident-group.technologische-omgeving {
  background-color: #e3efd9;
}

.popup.content h2.inicident-group.vitale-infrastructuur-en-voorzieningen {
  background-color: #fef2cf;
}

.popup.content h2.inicident-group.gebouwde-omgeving {
  background-color: #f7d4d5;
}

.popup.content h2.inicident-group.verkeer-en-vervoer {
  background-color: #f6e6ef;
}

.popup.content h2.inicident-group.gezondheid {
  background-color: #fae4d7;
}

.popup.content h2.inicident-group.sociaal-maatschappelijke-omgeving {
  background-color: #dbd0e4;
}

@media screen and (min-width: 761px) {
  .popup.content .buttons {
    height: 100%;
    flex-flow: wrap;
    place-content: center;
    align-items: center;
    display: flex;
  }

  .popup.content .buttons .button {
    margin: 0 5px 5px 0;
    font-size: 16px;
    position: relative;
  }

  .popup.content .buttons .button .break {
    display: none;
  }
}

.popup.content .alphen-aan-den-rijn svg #alphen-aan-den-rijn-2, .popup.content .bodegraven-reeuwijk svg #bodegraven-reeuwijk-2, .popup.content .gouda svg #gouda-2, .popup.content .hillegom svg #hillegom-2, .popup.content .kaag-en-braassem svg #kaag-en-braassem-2, .popup.content .katwijk svg #katwijk-2, .popup.content .krimpenerwaard svg #krimpenerwaard-2, .popup.content .leiden svg #leiden-2, .popup.content .leiderdorp svg #leiderdorp-2, .popup.content .lisse svg #lisse-2, .popup.content .nieuwkoop svg #nieuwkoop-2, .popup.content .noordwijk svg #noordwijk-2, .popup.content .oegstgeest svg #oegstgeest-2, .popup.content .teylingen svg #teylingen-2, .popup.content .voorschoten svg #voorschoten-2, .popup.content .waddinxveen svg #waddinxveen-2, .popup.content .zoeterwoude svg #zoeterwoude-2, .popup.content .zuidplas svg #zuidplas-2 {
  fill: #5dad2c;
}

.popup.content .compartment > .illustration input:checked ~ .buttons {
  display: flex;
}

.popup.content .compartment > .illustration input:checked ~ label {
  opacity: 1;
}

.fancybox__content .popup#header, .fancybox__content .popup.footer, .fancybox__content .popup.content a.back {
  display: none;
}

.fancybox__content .popup.content {
  max-height: 100%;
  max-height: 100%;
  overflow: auto;
}

.popup.section.footer .compartment {
  padding: 1em;
}

html {
  height: 100%;
  height: -webkit-fill-available;
}

body {
  height: 100%;
  margin: 0;
  font-family: Open Sans, sans-serif;
}

body.js {
  overflow: hidden;
}

a, a img {
  border: none;
}

.selector-icons {
  left: 10px;
  left: calc(env(safe-area-inset-left, 0)  + 10px);
  bottom: 40px;
  bottom: calc(env(safe-area-inset-bottom, 0)  + 40px);
  flex-flow: column;
  display: flex;
  position: absolute;
}

@media screen and (min-width: 375px) {
  .selector-icons {
    bottom: 10px;
    bottom: calc(env(safe-area-inset-bottom, 0)  + 10px);
  }
}

.selector-icons a {
  z-index: 10;
  color: #777;
  background-color: #fff;
  border-radius: 2em;
  flex-flow: row;
  align-items: center;
  margin-top: 10px;
  padding: 0 1em 0 0;
  font-size: 10px;
  font-weight: normal;
  text-decoration: none;
  display: none;
  position: relative;
  box-shadow: 0 2px 4px #0003, 0 -1px #00000005;
}

@media screen and (min-width: 800px) {
  .selector-icons a {
    font-size: 14px;
  }
}

.selector-icons a img {
  float: left;
  opacity: .6;
  height: 30px;
  width: 30px;
  max-height: 30px;
  max-width: 30px;
  margin-right: 5px;
}

.selector-icons a svg {
  pointer-events: none;
  height: 3em;
  margin-right: .5em;
}

.selector-icons a svg .legenda-gemeenten-1 {
  fill: #bbb;
}

.selector-icons a svg .legenda-gemeenten-2, .selector-icons a svg .legenda-crisistypen-2 {
  fill: #fff;
}

.selector-icons a svg .legenda-crisistypen-1 {
  fill: #bbb;
}

.selector-icons a span {
  flex: 1 0 60px;
  display: block;
}

@media screen and (min-width: 761px) {
  .selector-icons a span {
    flex: none;
    display: inline;
  }
}

.selector-icons a.active {
  color: #fff;
}

.selector-icons a.active#gemeenten-filter {
  background-color: #4b89cb;
}

.selector-icons a.active#crisistypen-filter {
  background-color: #7f408e;
}

.selector-icons a.active img {
  opacity: 1;
}

.selector-icons a.active svg .legenda-gemeenten-1 {
  fill: #1e5590;
}

.selector-icons a.active svg .legenda-gemeenten-2, .selector-icons a.active svg .legenda-crisistypen-2 {
  fill: #fff;
  transform-origin: 50%;
  transform: scale(.95);
}

.selector-icons a.active svg .legenda-crisistypen-1 {
  fill: #60306b;
}

.selector-icons a.visible {
  display: flex;
}

@media screen and (min-width: 761px) {
  .selector-icons a {
    font-size: 14px;
  }

  .selector-icons a img {
    height: 55px;
    width: 55px;
    max-height: 55px;
    max-width: 55px;
  }

  .selector-icons.visible {
    display: flex;
  }
}

.label {
  bottom: 0;
  bottom: env(safe-area-inset-bottom, 0);
  right: 0;
  right: env(safe-area-inset-right, 0);
  z-index: 10;
  flex-flow: column;
  font-size: 11px;
  display: flex;
  position: absolute;
}

@media screen and (min-width: 761px) {
  .label {
    font-size: 14px;
  }
}

.label .brand {
  background-color: #fffc;
  flex-flow: row;
  justify-content: flex-end;
  align-items: baseline;
  padding: 2px 10px;
  display: flex;
}

.label .brand h1 {
  color: #7f408e;
  font-size: 11px;
  font-weight: normal;
}

@media screen and (min-width: 761px) {
  .label .brand h1 {
    font-size: 14px;
  }
}

.label .brand img {
  height: 11px;
  max-height: 10px;
  width: 100px;
  object-fit: cover;
}

@media screen and (min-width: 800px) {
  .label .brand img {
    width: 200px;
    height: 14px;
  }
}

.label .legend {
  display: none;
}

.label .legend.visible {
  box-shadow: none;
  color: #fff;
  flex-flow: row;
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

.fullscreenToggle {
  display: none;
}

#mapsection {
  height: 100%;
  height: -webkit-fill-available;
  max-width: 100%;
}

#mapsection .risicoprofiel-wrapper {
  width: 100vw;
  flex-flow: column;
  justify-content: center;
  display: flex;
}

#mapsection #risicoprofiel-inner {
  height: 100%;
  width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

.f-panzoom {
  --f-panzoom-pin-width: auto;
  --f-panzoom-pin-height: auto;
}

:root {
  --f-button-bg: #fff;
}

@media screen and (min-width: 768px) {
  :root {
    --f-button-width: 46px;
    --f-button-height: 46px;
  }
}

.f-panzoom__toolbar {
  z-index: 10;
  margin: 10px calc(10px + env(safe-area-inset-right, 0)) calc(10px + env(safe-area-inset-bottom, 0));
  background-color: #fff;
  align-self: flex-end;
  font-size: 11px;
  position: absolute;
  top: 0;
  box-shadow: 0 2px 4px #0003, 0 -1px #00000005;
}

@media screen and (min-width: 800px) {
  .f-panzoom__toolbar {
    font-size: 14px;
  }
}

@media screen and (min-width: 545px) {
  .f-panzoom__toolbar {
    margin: 10px calc(10px + env(safe-area-inset-right, 0)) calc(10px + env(safe-area-inset-bottom, 0));
  }
}

#risicoprofiel .button {
  scale: var(--panzoom-zoom-factor);
  transform-origin: 0 0;
  text-align: center;
  color: #000;
  background-color: #fffc;
  font-size: 42px;
  line-height: 1em;
  text-decoration: none;
  display: block;
}

#risicoprofiel .not-on-map {
  flex-flow: column;
  align-items: center;
  display: flex;
  scale: 1;
}

#risicoprofiel .not-on-map a.button {
  height: auto;
  font-size: calc(42px * var(--panzoom-zoom-factor));
  transform-origin: 0 0;
  transform-box: fill-box;
  scale: 1;
}

#risicoprofiel .not-on-map a.crisistype.button {
  margin: 0 0 1em;
  transform: skew(-45deg)scale(1)translate(0);
}

@media screen and (min-width: 768px) {
  #risicoprofiel .button {
    display: block;
  }
}

#risicoprofiel .button, .popup.content .button {
  word-break: keep-all;
  min-width: 8em;
  width: max-content;
  max-width: 15em;
  color: #000;
  background-color: #fffc;
  outline: none;
  padding: .5em 1em;
  line-height: 1em;
  text-decoration: none;
  display: none;
}

#risicoprofiel .button .btn, .popup.content .button .btn {
  white-space: normal;
  cursor: pointer;
  appearance: none;
  border: none;
  border-radius: 1rem;
  outline: none;
  padding: .5rem 1.8rem;
  font-weight: bold;
  display: inline;
}

#risicoprofiel .button + div, .popup.content .button + div {
  display: none;
}

#risicoprofiel .button a, .popup.content .button a {
  color: #000;
  text-align: center;
  height: 100%;
  flex-flow: column;
  justify-content: flex-end;
  text-decoration: none;
  display: flex;
}

#risicoprofiel .button.aanjager, .popup.content .button.aanjager {
  color: #fff;
  background-color: #0b836dcc;
  font-size: 60px;
  font-weight: bold;
}

#risicoprofiel .button.aanjager:hover, .popup.content .button.aanjager:hover {
  background-color: #0b836d;
}

#risicoprofiel .button:hover, .popup.content .button:hover {
  cursor: pointer;
  opacity: 1;
}

#risicoprofiel .button:active, #risicoprofiel .button:focus, #risicoprofiel .button:focus-visible, #risicoprofiel .button:focus-within, .popup.content .button:active, .popup.content .button:focus, .popup.content .button:focus-visible, .popup.content .button:focus-within {
  outline: none;
}

#risicoprofiel .button.crisistype, .popup.content .button.crisistype {
  color: #fff;
  background-color: #7f408e;
}

#risicoprofiel .button.crisistype:hover, .popup.content .button.crisistype:hover {
  background-color: #8f48a0;
}

#risicoprofiel .button.gemeente, .popup.content .button.gemeente {
  color: #fff;
  background-color: #1e5590;
  border-radius: 0;
  font-weight: bold;
  position: relative;
}

#risicoprofiel .button.gemeente:hover, .popup.content .button.gemeente:hover {
  background-color: #3676bb;
}

#risicoprofiel .button.gemeente:hover:after, .popup.content .button.gemeente:hover:after {
  border-top-color: #3676bb;
}

#risicoprofiel .button.gemeente:after, .popup.content .button.gemeente:after {
  content: "";
  width: 0;
  height: 0;
  border: 1em solid #0000;
  border-top-color: #1e5590;
  margin-left: -1em;
  position: absolute;
  top: 99%;
  left: 50%;
  right: 50%;
}

.popup.content .button {
  display: block;
}

.popup.content .button.gemeente + div {
  display: none;
}

#risicoprofiel {
  height: 100%;
  max-height: 100%;
  max-height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  display: none;
  inset: 0;
}

#risicoprofiel #mapimage {
  width: 4900px;
  height: 3900px;
  pointer-events: none;
}

#mapsection.visible #risicoprofiel {
  display: flex;
}

#risicoprofiel #risicoprofiel-wrapper {
  width: 4900px;
  height: 3900px;
}

#risicoprofiel #kaart-crisistypen, #risicoprofiel #kaart-aanjagers {
  opacity: 1;
  transition: opacity .3s;
  display: none;
}

#risicoprofiel #kaart-crisistypen.f-panzoom__content, #risicoprofiel #kaart-aanjagers.f-panzoom__content {
  display: block;
}

#risicoprofiel #kaart-aanjagers {
  opacity: 0;
}

#risicoprofiel.show-crisistypen a.button.crisistype, #risicoprofiel.show-gemeenten a.button.gemeente {
  display: block;
}

#risicoprofiel[data-map="kaart-aanjagers"] #kaart-aanjagers {
  opacity: 1;
}

#risicoprofiel[data-map="kaart-aanjagers"] #kaart-crisistypen {
  opacity: 0;
}

#risicoprofiel[data-map="kaart-aanjagers"] a.button.aanjager {
  display: block;
}

svg#risicoprofiel-inner-svg {
  pointer-events: none;
}

svg#risicoprofiel-inner-svg foreignObject {
  pointer-events: all;
  position: relative;
}

svg#risicoprofiel-inner-svg foreignObject > div {
  height: 100%;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

svg#risicoprofiel-inner-svg foreignObject.crisistype > div {
  justify-content: flex-end;
}

#risicoprofiel a.crisistype.button {
  margin: 0;
  padding: .5em 1em;
  line-height: 1em;
  transform: skew(-45deg);
}

#risicoprofiel a.crisistype.button span {
  display: inline-block;
  transform: skew(45deg);
}

#risicoprofiel a.crisistype.button circle {
  fill: #0000;
}

#risicoprofiel a.crisistype.button:hover {
  opacity: 1;
}

#risicoprofiel a.crisistype.button:hover circle {
  fill: #0003;
}

.map.main .loader {
  content: "";
  height: 100%;
  width: 100%;
  z-index: -10;
  background-color: #dedede;
  padding: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.7df0e321.css.map */
