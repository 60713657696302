#header {
  // padding-bottom: 1em;
  // padding-top: 1em;
  // max-height: 25vh;
  overflow-y: auto;
  height: 50px;

  @media screen and (min-width: 545px) {
    height: 64px;
    background: linear-gradient(to right, #ffffff 0%, #ffffff 50%, #ffffff 50%, #9e51b0 51%, #9e51b0 100%);
  }

  // flex: 0 0 10vh;
  // min-height: 60px;
  // max-height: 80px;
  // position: absolute;
  width: 100%;
  // background-color: rgba(#fff, 0.9);
  // background-color: $primary-colour;
  z-index: 10;

  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
  #mapsection.visible & {
    position: relative;
  }

  .compartment {
    height: 100%;
    max-height: 100%;
    padding-bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    body.show-tables & {
      justify-content: space-between;
    }
    background-color: $primary-colour;
    @media screen and (min-width: 375px) {
      justify-content: space-between;
    }

    align-items: center;
    // background-image: url('../assets/images/blokjes-transparant.svg');
    // background-position: top 0px right 10px;
    // background-repeat: no-repeat;
    // background-size: 100px 25px;
    // @media screen and (min-width: 800px) {
    //   background-size: 200px 50px;
    // }
    // @media screen and (min-width: 761px) {
    //   background-size: 200px auto;
    // }
  }

  h1 {
    flex-grow: 2;
    font-weight: normal;
    text-align: right;
    // align-self: flex-end;
    // padding-top: 1em;
    font-size: 1.5em;

    @media screen and (min-width: 981px) {
      font-size: 2em;
    }
  }

  .header-title {
    text-align: right;
    // background-image: url('../assets/images/blokjes-transparant.svg');
    // background-position: top right;
    // background-repeat: no-repeat;
    // background-size: auto 50px;
  }

  a.back {
    margin-right: 10px;
    // margin-top: 60px;
  }
}

// #header img.blokjes {
//   max-height: 40px;
// }



div.toggle {
  // @include above-map;
  // z-index: 100;
  display: flex;
  flex-flow: row nowrap;
  // top: 100px;
  // max-height: 50px;
  // max-width: 40px;
  // margin-top: -25px;
  // right: 10px;
  // padding: 15px 10px 10px;
  box-sizing: border-box;
  // padding: 11px 11px 11px 0;

  // border-radius: 25px;
  a {
    pointer-events: all;
    cursor: pointer;
    height: 2em;
    font-size: 1.2em;
    padding: .5em 1em;
    // border-left: 1px solid #c1c1c1;
    border-left: 1px solid #fff;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    // max-height: 100%;
    img,
    svg {
      // height: 47px;
      cursor: pointer;
      height: 18px;
      font-size: 18px;

      @media screen and (min-width: 445px) {
        height: 22px;
        font-size: 22px;
      }

      // height: 14px;
    }

    .button_container {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: flex-end;
      position: relative;
      width: 1.2em;
      height: 1.2em;
      // padding-left: env(safe-area-inset-right);
      // height: 100%;
      min-height: 1em;
      font-size: 1.5rem;
      top: auto;
      right: auto;
      cursor: pointer;
      z-index: 100;
      -webkit-transition: opacity .25s ease, top .5s ease;
      -o-transition: opacity .25s ease, top .5s ease;
      transition: opacity .25s ease, top .5s ease;
    }

    .button_container span {
      // fill: #CFD1D2;
      // fill: #fff;
      // stroke-width: 0;
      // background-color: $primary-colour;
      background-color: #fff;
      border-radius: .06em;
      border: none;
      height: .12em;
      // margin-top: -3px;
      width: 75%;
      position: relative;
      top: auto;
      right: auto;
      left: auto;
      cursor: pointer;
      transition: transform .3s ease, opacity .3s ease, width .3s ease;
      &:before {
        content: '';
        display: block;
        height: .12em;
        // background-color: $primary-colour;
        background-color: #fff;
        border-radius: .06em;
        width: .12em;
        margin-left: -25%;
      }
    }

    &.active {
      .button_container span {
        width: 100%;
      }
      .button_container span:before {
        opacity: 0;
      }

      .button_container span.top {
        transform-origin: 50% 50%;
        transform: translate(0, 0.5em)rotate(45deg);
      }

      .button_container span.middle {
        opacity: 0;
      }

      .button_container span.bottom {
        transform-origin: 50% 50%;
        transform: translate(0, -0.55em)rotate(-45deg);
      }

      // svg path {
      //   fill: #000;
      //   // stroke: #000;
      // }
    }

    @media screen and (max-width: 445px) {
      body.show-tables & {
        border-left: none;
      }
    }
    @media screen and (min-width: 545px) {
      font-size: 1em;
    }
    // @media screen and (min-width: 445px) {
      // .button_container span, .button_container span:before {
      //   background-color: #fff;
      // }
    // }
  }
}


#logo {
  // display: none;
  opacity: 0;
  transform: skew(-45deg) translate(-3em);
  transition: left .2s, opacity .3s;
  left: -100%;
  top: 0;
  position: absolute;
  display: block;
  height: 50px;
  background-color: white;
  padding: 0 4em 0 calc(env(safe-area-inset-left, 0)  + 10px);
  max-height: 100%;
  flex: 0 1 200px;
  // margin-right: 50px;

  body.show-tables & {
    opacity: 1;
    left: 0;
    position: relative;
  }
  // @media screen and (min-width: 445px) {
  
  @media screen and (min-width: 375px) {
    
    opacity: 1;
    position: relative;
    display: block;
    left: 0;
    height: 100%;
    // body.hiding-tables {
    //   opacity: 0;
    // }
    // &:after {
    //   content: "";
    //   height: 100%;
    //   box-sizing: border-box;
    //   background-color: inherit;
    //   aspect-ratio: 1 / 1;
    //   /* margin-left: -50%; */
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   border-left: inherit;
    //   transform: translate(99%, 0) rotate(180deg);
    //   mask: url('../assets/images/driehoek.svg');
    //   mask-mode: alpha;
    // }
  }

  a {
    display: block;
    transform: skew(45deg) translate(3em);
    // height: 100%;
    // max-height: 100%;
    box-sizing: border-box;
    height: 100%;
    max-width: 100%;
  }

  // text-align: left;
  img {

    display: block;
    // object-fit: contain;
    // height: 80px;
    // width: 270px;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    // max-width: 300px;
    padding: 10px 0;
    box-sizing: border-box;
  }


  // @media screen and (min-width: 761px) {
  //   margin-left: 110px;
  // }
  @media screen and (min-width: 545px) {
    // margin-left: 210px;
    flex: 0 1 365px;
  }

  @media screen and (min-height: 600px) {
    img {
      padding: 10px 0;
      max-height: 100%;
    }
  }
}



.map-selectors,
.info-selectors {

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  @media screen and (min-width: 445px) {
    // justify-content: flex-end;
  }
}

// .map-selectors {
//   flex: 1 1 auto;
//   transition: opacity .3s;
//   opacity: 1;
//   visibility: visible;
//   body.showing-tables & {

//   }
//   body.show-tables & {
//     opacity: 0;
//     visibility: hidden;
//   }
//   body.showing-tables & {
//     opacity: 0;
//     visibility: visible;
//   }
//   body.hiding-tables & {
//     opacity: 1;
//     visibility: visible;
//   }
//   a {
//     // height: 100%;
//     font-weight: bold;
//     // border-radius: 0;
//     padding: .5em 1em;
//     // margin-bottom: 0.5em;
//     text-decoration: none;
//     color: #777;
//     border-left: 1px solid #c1c1c1;

//     display: flex;
//     flex-flow: row nowrap;
//     align-items: center;
//     &:first-of-type {
//       border-left: none;
//     }

//     &.active {
//       color: #000;
//       font-weight: bold;
//       svg {
//         .icon-crisistypes-1 {
//           fill: #000;
//         }

//         .icon-aanjagers-1 {
//           fill: #000;
//         }
//       }
//     }
//     svg {
//       pointer-events: none;
//       height: 2em;
//       margin-right: .5em;
//       .icon-crisistypes-1 {
//         fill: #777;
//         // stroke: #777;
//         // stroke-miterlimit: 10;
//         // stroke-width: 0.8;
//       }

//       .icon-aanjagers-1 {
//         fill: #777;
//         // stroke: #777;
//         // stroke-miterlimit: 10;
//         // stroke-width: 0.43;
//       }
//     }
//   }

//   @media screen and (min-width: 761px) {
//     font-size: 14px;
//   }
// }

.info-selectors {
  flex: 0 auto;
  position: relative;
  padding: 0 0 env(safe-area-inset-right, 0) 0;
  // transform: skew(-45deg) translate(3em);
  // padding: 0 0 0 3em;
  &::before {
    content: "";
    height: 100%;
    box-sizing: border-box;
    background-color: inherit;
    aspect-ratio: 1 / 1;
    /* margin-left: -50%; */
    position: absolute;
    top: 0;
    left: 0;
    border-right: inherit;
    z-index: 1;
    transform: skew(-45deg) translate(-50%);
  }
  #show-info, .toggle {
    z-index: 2;
    user-select: none;
  }
  // background-color: $primary-colour;
  @media screen and (min-width: 545px) {
    background-color: $primary-colour;
    // &:before {
    //   content: "";
    //   height: 100%;
    //   box-sizing: border-box;
    //   background-color: inherit;
    //   aspect-ratio: 1 / 1;
    //   /* margin-left: -50%; */
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   border-right: inherit;
    //   transform: translate(-99%,0);
    //   mask: url('../assets/images/driehoek.svg');
    // }
  }
  @media screen and (min-width: 650px) {
    background-color: lighten($primary-colour, 10%);
  }
}

#show-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: .5em 1em;
  transition: border .3s;

  // @media screen and (min-width: 445px) {
  //   // border-left: 1px solid #c1c1c1;
  //   body.show-tables &, body.showing-tables & {
  //     // border-left: 1px solid transparent;
  //   }
  //   body.hiding-tables & {
  //     // border-left: 1px solid #c1c1c1;
  //   }
  // }
  svg,
  img {
    cursor: pointer;
    // pointer-events: none;
    height: 2em;
    path.letter {
      fill: #fff;
    }
  }

  @media screen and (max-width: 375px) {
    body.show-tables & {
      display: none;
    }
  }
}