
.popup.content {
  padding: 1em;
  line-height: 1.5em;

  .content-layout {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;

    .column-2-5 {
      order: 2;
      padding: 20px;

      @media screen and (min-width: 981px) {
        padding: 20px 20px 20px 0;
        order: 0;
      }

      flex: 1 0 40%;

      img {
        min-width: 100px;
        max-width: 100%;

        @media screen and (min-width: 981px) {
          max-width: 250px;
        }
      }
    }

    @media screen and (min-width: 768px) {
      .columns {
        // flex: 1 1 60%;
        columns: 2;
        p {
          margin-top: 0;
        }
      }
    }

    // .column-3-3 {
    //   flex: 1 1 100%;
    // }
  }

  a.back {
    display: block;
    margin-top: 50px;
  }

  .compartment {
    max-width: 800px;

    &>.illustration {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      position: relative;
      input {
        opacity: 0;
        z-index: 1001;
        position: absolute;
        top: 10px;
      }
      .filter {
        display: flex;
        // position: absolute;
        // display: block;
        // top: 10px;
        // left: 10px;
        // display: none;
        // position: absolute;
        // top: 10px;
        padding: 10px;
        // z-index: 1000;
        display: block;
        background-color: #d40f2f;
        border-radius: 1em;
        line-height: 1em;
        padding: 0.5em 1em;
        // opacity: 0.8;
        // border-radius: 0;
        // margin-bottom: 0.5em;
        text-decoration: none;
        color: #fff;

          // &.active {
          //   opacity: 1;
          //   color: #fff;
          // }
        &.gemeenten {
          background-color: #F7921E;
        }

        @media screen and (min-width: 761px) {
          font-size: 14px;
        }
      }

      img {
        pointer-events: none; // Prevent Microsoft Edge Bing visual search bullshit
        // object-fit: contain;
        width: 100%;

        @media screen and (min-width: 981px) {
          width: auto;
          max-height: 70vh;
          max-width: 800px;
        }

        // min-height: 300px;
        // max-height: 50vh;
        // display: block;
        // object-fit: contain;
        // max-width: 100%;
        margin: 0 auto;
      }

      .buttons {
        display: none;
        padding-top: 1em;

        

        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        align-content: space-evenly;

        .button {
          position: relative;
          margin: 0 1em 1.5em 0;
          font-size: 12px;
        }
      }
    }
  }

  h1 {
    font-weight: normal;
    padding: .2em 1rem .2em 0;
    margin: 0 0 0.5em;
    border-bottom: 1px solid #dedede;
    font-size: 20px;
    line-height: 30px;

    @media screen and (min-width: 761px) {
      font-size: 40px;
      line-height: 50px;
    }

    .break {
      display: none;
    }
  }
  
  h2.inicident-group {
    font-weight: normal;
    margin: 0;
    padding: 1rem;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    &:before {
      content: "";
      height: 100%;
      box-sizing: border-box;
      background-color: inherit;
      aspect-ratio: 1 / 1;
      /* margin-left: -50%; */
      position: absolute;
      top: 0;
      left: 0;
      border-right: inherit;
      transform: translate(-99%,0);
      mask: url('../assets/images/driehoek.svg');
    }
    &:after {
      content: "";
      height: 100%;
      box-sizing: border-box;
      background-color: inherit;
      aspect-ratio: 1 / 1;
      /* margin-left: -50%; */
      position: absolute;
      top: 0;
      right: 0;
      border-left: inherit;
      transform: translate(99%,0) rotate(180deg);
      mask: url('../assets/images/driehoek.svg');
      mask-mode: alpha;
    }

    &.natuurlijke-omgeving {
      background-color: #d9e3f1;
    }

    &.technologische-omgeving {
      background-color: #e3efd9;
    }

    &.vitale-infrastructuur-en-voorzieningen {
      background-color: #fef2cf;
    }

    &.gebouwde-omgeving {
      background-color: #f7d4d5;
    }

    &.verkeer-en-vervoer {
      background-color: #f6e6ef;
    }

    &.gezondheid {
      background-color: #fae4d7;
    }

    &.sociaal-maatschappelijke-omgeving {
      background-color: #dbd0e4;
    }
  }
  
  @media screen and (min-width: 761px) {
    .buttons {
      height: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      // position: absolute;
      // float: left;
      // top: 0;
      // left: 0;

      .button {
        position: relative;
        margin: 0 5px 5px 0;
        font-size: 16px;

        // min-height: 50px;
        .break {
          display: none;
        }
      }
    }
  }
  
  @each $gemeente in alphen-aan-den-rijn,
  bodegraven-reeuwijk,
  gouda,
  hillegom,
  kaag-en-braassem,
  katwijk,
  krimpenerwaard,
  leiden,
  leiderdorp,
  lisse,
  nieuwkoop,
  noordwijk,
  oegstgeest,
  teylingen,
  voorschoten,
  waddinxveen,
  zoeterwoude,
  zuidplas {
    .#{$gemeente} svg ##{$gemeente}-2 {
      fill: #5DAD2C;
    }
  }
}
  
.popup.content .compartment > .illustration input:checked ~ .buttons {
  display: flex;

}

.popup.content .compartment > .illustration input:checked ~ label {
  opacity: 1;
}

.fancybox__content {

  // height: 100%;
  // width: 100%;
  // overflow-y: none;
  // transform: translate3d(0,0,0);
  // max-width: 1100px;
  // max-height: 100%;
  // max-height: calc(var(--vh, 1vh) * 100);
  .popup#header,
  .popup.footer,
  .popup.content a.back {
    display: none;
  }

  .popup.content {
    max-height: 100%;
    max-height: 100%;
    overflow: auto;
  }
}

.popup.section.footer .compartment {
  padding: 1em;
}

.popup.footer {}
