/* ================
// Sections
// ============= */

.above-fold {
  // max-height: 100vh;
  // display: flex;
  // flex-flow: column nowrap;
}

.section {
  // padding: 0 em(20);
  padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

// .section.map {
//   padding: 0;
//   max-height: 100%;
//   img {
//     display: block;
//   }
// }
//
// .section.main.map {
//   max-height: 100%;
//   img {
//     max-height: 100%;
//     max-width: 100%;
//   }
// }
//
// .main.map {
//   display: block;
//   height: auto;
//   width: 1200px;
//   img {
//     width: 100%;
//   }
// }

// .section.zoomed.map {
//   // max-height: 100vh;
//   .compartment {
//     max-height: 778px;
//     overflow: scroll;
//   }
//   .scroll-window{
//     position: relative;
//   }
// }

.compartment {
  @include compartment();
}
