@import '../../node_modules/@fancyapps/ui/dist/panzoom/panzoom';
@import '../../node_modules/@fancyapps/ui/dist/panzoom/panzoom.toolbar';
@import '../../node_modules/@fancyapps/ui/dist/panzoom/panzoom.pins';
@import '../../node_modules/@fancyapps/ui/dist/fancybox/fancybox';

@import 'configuration';
@import 'structure/root';
@import 'elements/buttons';
@import 'mixins/functions';
@import 'mixins/compartment';
@import 'mixins/font-face';
@import 'mixins/cover';
@import 'mixins/clearfix';
@import 'structure/sections';

@import 'header';
@import 'tables';
@import 'popup';

@mixin above-map {
  position: absolute;
  z-index: 10;
  font-size: 11px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);

  @media screen and (min-width: 800px) {
    font-size: 14px;
  }
}

html {
  height: 100%;
  height: -webkit-fill-available;
}

body {
  // color: ;
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  // height: 100%;
  // height: stretch;
  // height: calc(100vh - env(safe-area-inset-bottom));
  // // height: calc(100% - env(safe-area-inset-bottom, 0));
  // max-height: -webkit-fill-available;
  // min-height: -webkit-fill-available;

  &.js {
    overflow: hidden;
  }
}

a {
  border: none;

  img {
    border: none;
  }
}

.selector-icons {
  // @include above-map;
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  left: 10px;
  left: calc(env(safe-area-inset-left, 0) + 10px);
  bottom: 40px;
  bottom: calc(env(safe-area-inset-bottom, 0) + 40px);
  @media screen and (min-width: 375px) {
    bottom: 10px;
    bottom: calc(env(safe-area-inset-bottom, 0) + 10px);
  }

  // padding: 10px 0;
  a {
    @include above-map;
    position: relative;
    font-weight: normal;
    padding: 0 1em 0 0;
    // display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: #777;
    text-decoration: none;
    font-size: 10px;
    display: none;
    margin-top: 10px;
    border-radius: 2em;

    // max-height: 60px;
    img {
      float: left;
      opacity: 0.6;
      height: 30px;
      width: 30px;
      max-height: 30px;
      max-width: 30px;
      margin-right: 5px;
    }
    svg {
      // background-color: #fff;
      pointer-events: none;
      height: 3em;
      margin-right: .5em;
      .legenda-gemeenten-1 {
        fill: #bbbbbb;
      }
      .legenda-gemeenten-2, .legenda-crisistypen-2 {
        fill: #fff;
      }
      .legenda-crisistypen-1 {
        fill: #bbbbbb;
      }
    }

    span {
      display: block;
      flex: 1 0 60px;

      @media screen and (min-width: 761px) {
        display: inline;
        flex: none;
      }
    }

    // @media (hover:hover) {
    //   &:hover {
    //     color: #000;
    //   }
    // }
    &.active {
      color: #fff;
      &#gemeenten-filter {
        background-color: #4b89cb;
      }
      &#crisistypen-filter {
        background-color: $primary-colour;
      }
      img {
        opacity: 1;
      }
      svg {
        .legenda-gemeenten-1 {
          fill: #1e5590;
        }
        .legenda-gemeenten-2, .legenda-crisistypen-2 {
          fill: #fff;
          transform: scale(0.95);
          transform-origin: 50% 50%;
        }
        .legenda-crisistypen-1 {
          fill: darken($primary-colour, 10%);
        }
      }
    }

    &.visible {
      display: flex;
    }
  }

  // .filters {
  //   display: flex;
  //   flex-flow: column nowrap;
  //   border-bottom: 1px solid #ddd
  // }

  @media screen and (min-width: 761px) {
    // .filters {
    //   // display: flex;
    //   // flex-flow: row nowrap;
    //   // border-bottom: none;
    //   // border-right: 1px solid #ddd;
    // }

    // flex-flow: row nowrap;
    a {
      // flex-flow: column nowrap;
      font-size: 14px;

      img {
        // float: none;
        height: 55px;
        width: 55px;
        max-height: 55px;
        max-width: 55px;
        // margin-right: 0;
      }
    }

    &.visible {
      display: flex;
    }

    // flex-flow: row nowrap;
    a {

      // border-top: none;
      // border-left: 1px solid #ddd;
      &:first-of-type {
        // border-bottom: none;
      }
    }
  }
}

.label {
  bottom: 0;
  bottom: env(safe-area-inset-bottom, 0);
  right: 0;
  right: env(safe-area-inset-right, 0);
  z-index: 10;
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  font-size: 11px;

  @media screen and (min-width: 761px) {
    font-size: 14px;
  }

  .brand {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: baseline;
    background-color: rgba(255, 255, 255, 0.8);
    // box-shadow: 0 2px 4px rgba(0,0,0,0.2),0 -1px 0px rgba(0,0,0,0.02);
    padding: 2px 10px;

    h1 {
      font-size: 11px;
      color: $primary-colour;

      @media screen and (min-width: 761px) {
        font-size: 14px;
      }

      font-weight: normal;
      // margin-right: 1em;
    }

    img {
      height: 11px;
      max-height: 10px;
      width: 100px;
      object-fit: cover;

      @media screen and (min-width: 800px) {
        width: 200px;
        height: 14px;
      }
    }
  }

  .legend {
    display: none;
  }

  .legend.visible {
    padding: 2px 10px;
    // @include above-map;
    box-shadow: none;
    display: flex;
    color: #fff;
    padding: 10px;
    justify-content: space-between;
    flex-flow: row nowrap;
    // background-image: url('../assets/images/klassen-gradient.png');
    // background-size: contain;
    // background-repeat: repeat-y;
    // min-width: 300px;
    // margin-left: -50%;
  }
}

.fullscreenToggle {
  display: none;
}

#mapsection {
  height: 100%;
  height: -webkit-fill-available;
  // position: relative;
  max-width: 100%;

  // .f-panzoom {
  //   max-width: 100%;
  //   max-height: 100%;
  // }
  // position: relative;
  // overflow: hidden;
  // @media screen and (min-width: 1400px) {
  //   background-image: url('../assets/images/back.jpg');
  //   background-position: left top;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  // }

  // height: 75vh;
  // height: calc(100vh - 10vh);
  // // max-height: 1000px;
  // max-height: calc(var(--ih) - 10vh);
  // flex: 1 0 75vh;
  // flex: 1 0 calc(100vh - 10vh);
  // flex: 1 0 calc(var(--ih) - 10vh);
  @media screen and (min-height: 800px) {
    // height: calc(100vh - 80px);
    // flex: 1 0 calc(100vh - 80px);
    // flex: 1 0 calc(var(--ih) - 80px);
    // max-height: calc(var(--ih) - 80px);
  }

  .risicoprofiel-wrapper {
    // height: calc(var(--ih) - 80px);
    width: 100vw;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  #risicoprofiel-inner {
    height: 100%;
    width: 100%;
  }

  // box-sizing: border-box;
  @media screen and (min-height: 1400px) {
    // max-height: 1000px;
    // margin-bottom: 100px;
  }

  @media screen and (min-height: 1170px) {
    // max-height: 1000px;
  }

}

svg:not(:root) {
  overflow: hidden;
}

.f-panzoom {
  --f-panzoom-pin-width: auto;
  --f-panzoom-pin-height: auto;
}

:root {
  // --f-button-color: #fff;
  --f-button-bg: #fff;
  // --f-button-width: 46px;
  // --f-button-height: 46px;
  @media screen and (min-width: 768px) {
    --f-button-width: 46px;
    --f-button-height: 46px;
  }
}

.f-panzoom__toolbar {
  align-self: flex-end;
  @include above-map;
  position: absolute;
  top: 0;
  margin: 10px calc(10px + env(safe-area-inset-right, 0)) calc(10px + env(safe-area-inset-bottom, 0));
  // font-size: 11px;
  @media screen and (min-width: 545px) {
    margin: 10px calc(10px + env(safe-area-inset-right, 0)) calc(10px + env(safe-area-inset-bottom, 0));
    // font-size: 14px;
  }
}

#risicoprofiel .button {
  // transform: var(--panzoom-zoom-factor);
  scale: var(--panzoom-zoom-factor);
  transform-origin: 0 0;
  // transition: scale .3s;
  // transform: translate(-50%,-50%) var(--panzoom-zoom-factor);
  // transform-origin: 50% 50%;
  // transform-origin: 0 0;
  display: block;
  text-align: center;
  // position: absolute;
  color: #000;
  text-decoration: none;
  background-color: rgba(255, 255, 255, .8);
  // border-radius: 1em;
  line-height: 1em;
  font-size: 42px;
  // font-size: calc(12px * var(--panzoom-img-scale));

  // @media screen and (min-width:981px) {
  //   font-size: 6px;
  // }

  // opacity: .8;
}

#risicoprofiel .not-on-map {
  scale: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  // height: 100%;
  // width: 100%;
  a.button {
    scale: 1;
    height: auto;
    // scale: var(--panzoom-zoom-factor);
    font-size: calc(42px * var(--panzoom-zoom-factor));
    transform-origin: 0 0;
    transform-box: fill-box;
    // margin-bottom: 1em;
  }
  a.crisistype.button {
    transform: skew(-45deg) scale(1) translate(0, 0);
    margin: 0 0 1em;

  }
}

@media screen and (min-width: 768px) {
  #risicoprofiel .button {
    display: block;
  }
}

.button {

  #risicoprofiel &,
  .popup.content & {
    word-break: keep-all;
    min-width: 8em;
    width: max-content;
    max-width: 15em;
    color: #000;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.8);
    outline: none;

    .btn {
      // height: auto;
      border-radius: 1rem;
      padding: .5rem 1.8rem;
      font-weight: bold;
      border: none;
      white-space: normal;
      display: inline;
      cursor: pointer;
      outline: none;
      appearance: none;
    }

    display: none;

    & + div {
      display: none;
    }

    // position: absolute;
    a {
      text-decoration: none;
      color: #000;
      text-align: center;
      display: flex;
      height: 100%;
      flex-flow: column nowrap;
      justify-content: flex-end;
    }

    // border-radius: 1em;
    line-height: 1em;
    padding: 0.5em 1em;
    // font-size: 5px;

    // @media screen and (min-width: 761px) and (min-height: 761px) {
    //   font-size: 5px;
    // }

    // @media screen and (min-width: 981px) and (min-height: 981px) {
    //   font-size: 6px;
    // }

    // @media screen and (min-width: 1200px) and (min-height: 1200px) {
      // font-size: 24px;
    // }

    &.aanjager {
      font-size: 60px;
      background-color: rgba(11, 131, 109, 0.8);

      &:hover {
        background-color: rgba(11, 131, 109, 1);
      }

      color: #fff;
      font-weight: bold;
      // font-size: 16px;

      // @media screen and (min-width: 761px) and (min-height: 761px) {
      //   font-size: 18px;
      // }

      // @media screen and (min-width: 981px) and (min-height: 981px) {
        // font-size: 60px;
      // }
    }

    // opacity: 0.8;
    // &.visible.enabled {
    //   display: block;
    // }
    // @media (hover:hover) {
    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    &:active, &:focus, &:focus-visible, &:focus-within {
      outline: none;
    }
    &.crisistype {
      background-color: $primary-colour;
      color: #fff;
      &:hover {
        background-color: lighten($primary-colour, 5%);
      }
    }

    // }
    // &.natuurlijke-omgeving {
    //   background-color: #d9e3f1;
    //   &:hover {
    //     background-color: lighten(#d9e3f1, 5%);
    //   }
    // }

    // &.technologische-omgeving {
    //   background-color: #e3efd9;
    //   &:hover {
    //     background-color: lighten(#e3efd9, 5%);
    //   }
    // }

    // &.vitale-infrastructuur-en-voorzieningen {
    //   background-color: #fef2cf;
    //   &:hover {
    //     background-color: lighten(#fef2cf, 5%);
    //   }
    // }

    // &.gebouwde-omgeving {
    //   background-color: #f7d4d5;
    //   &:hover {
    //     background-color: lighten(#f7d4d5, 5%);
    //   }
    // }

    // &.verkeer-en-vervoer {
    //   background-color: #f6e6ef;
    //   &:hover {
    //     background-color: lighten(#f6e6ef, 5%);
    //   }
    // }

    // &.gezondheid {
    //   background-color: #fae4d7;
    //   &:hover {
    //     background-color: lighten(#fae4d7, 5%);
    //   }
    // }

    // &.sociaal-maatschappelijke-omgeving {
    //   background-color: #dbd0e4;
    //   &:hover {
    //     background-color: lighten(#dbd0e4, 5%);
    //   }
    // }

    &.gemeente {
      position: relative;
      border-radius: 0;
      color: #fff;
      font-weight: bold;
      background-color: #1e5590;

      // @media (hover:hover) {
      &:hover {
        background-color: #3676bb;

        &:after {
          border-top-color: #3676bb;
        }

        // }
      }

      &:after {
        content: '';
        // display: block;
        // position: absolute;
        // top: 100%;
        // left: 50%;
        width: 0;
        height: 0;
        position: absolute;
        top: 99%;
        left: 50%;
        right: 50%;
        margin-left: -1em;
        // margin-left: -1em;
        border-top: 1em solid #1e5590;
        border-bottom: 1em solid transparent;
        border-left: 1em solid transparent;
        border-right: 1em solid transparent;
      }
    }

    @media screen and (min-width: 768px) {
      // font-size: 16px;
    }

    @media screen and (min-width: 981px) {
      // font-size: 14px;
    }
  }

  .map.zoomed & {
    // font-size: 16px;
  }

  .popup.content & {
    display: block;

    &.gemeente {
      &+div {
        display: none;
      }
    }
  }
}

#risicoprofiel {
  display: none;
  height: 100%;
  // height: 100vh;
  max-height: 100%;
  max-height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  // position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // width: 100vw;
  #mapimage {
    width: 4900px;
    height: 3900px;
    pointer-events: none;
  }
  #mapsection.visible & {
    display: flex;
  }
  // height: 100%;
  // width: 100%;
  // min-height: -webkit-fill-available;
  // max-width: -webkit-fill-available;
  // max-width: 100vw;
  // max-height: -webkit-fill-available;
  // position: absolute;
  // top: 0;
  // left: 0;
  // border: 1px solid green;
  // box-sizing: border-box;
  .f-panzoom__viewport {
    // border: 1px solid red;
    // box-sizing: border-box;
  }
  #risicoprofiel-wrapper {
    width: 4900px;
    height: 3900px;
    // aspect-ratio: 4/3;
    // height: 6000px;
    // width: 4000px;
    // height: 3000px;
    // position: relative;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // // display: flex;
    // &.absolute {
    //   position: absolute;
    // }
  }
  // height: 100%;
  // min-height: -webkit-fill-available;
  // max-height: -webkit-fill-available;
  svg {
    // position: absolute;
    // top: 0;
    // left: 0;
  }
  #kaart-crisistypen, #kaart-aanjagers {
    display: none;
    &.f-panzoom__content {
      display: block;
    }
    // background-image: url('/assets/images/vrhm-risicoprofiel.jpeg');
    // position: absolute;
    // top: 0;
    // left: 0;
    // display: block;
    opacity: 1;
    transition: opacity .3s;
  }
  #kaart-aanjagers {
    opacity: 0;
  }
  &.show-crisistypen {
    a.button.crisistype {
      display: block;
    }
  }
  &.show-gemeenten {
    a.button.gemeente {
      display: block;
    }
  }
  &[data-map='kaart-aanjagers'] {
    #kaart-aanjagers {
      opacity: 1;
    }
    #kaart-crisistypen {
      opacity: 0;
    }
    a.button.aanjager {
      display: block;
    }
  }
}

svg#risicoprofiel-inner-svg {
  // object-fit: cover;
  
  pointer-events: none; // Prevent Microsoft Edge Bing visual search bullshit
  // height: 2700px;
  // width: 3600px;
  // transition: transform 0.1s;

  // transform: scale(2);
  // transform-origin: 50% 50%;
  // max-width: 100%;
  // max-height: 100%;
  // min-height: 100%;
  // min-width: 100%;
  // object-fit: cover;
  // // transform: translate3d(0, 0, 0) scale(1);
  // transform-origin: 0 0;
  // transition: none;
  // touch-action: none;
  // user-select: none;
  foreignObject {
    position: relative;
    pointer-events: all;
    & > div {
      display: flex;
      height: 100%;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
    }

    &.crisistype > div {
      justify-content: flex-end;
    }
  }
}

#risicoprofiel a.crisistype.button {
  // background-color: transparent;
  // opacity: 0.8;
  padding: .5em 1em;
  line-height: 1em;
  margin: 0;
  transform: skew(-45deg);
  span {
    transform: skew(45deg);
    display: inline-block;
  }
  // border-left: 50px solid transparent;
  // border-right: 50px solid transparent;
  // border-bottom: 100px solid black;
  // &:before {
  //   content: "";
  //   height: 100%;
  //   box-sizing: border-box;
  //   background-color: inherit;
  //   aspect-ratio: 1 / 1;
  //   /* margin-left: -50%; */
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   border-right: inherit;
  //   transform: translate(-99.9%,0);
  //   mask: url('../assets/images/driehoek.svg');
  // }
  // &:after {
  //   content: "";
  //   height: 100%;
  //   box-sizing: border-box;
  //   background-color: inherit;
  //   aspect-ratio: 1 / 1;
  //   /* margin-left: -50%; */
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   border-left: inherit;
  //   transform: translate(99%,0) rotate(180deg);
  //   mask: url('../assets/images/driehoek.svg');
  //   mask-mode: alpha;
  // }
  circle {
    fill: rgba(0, 0, 0, 0);
    // opacity: 0;
  }

  // @media (hover:hover) {
  &:hover {

    opacity: 1;
    circle {
      fill: rgba(0, 0, 0, 0.2);
      // opacity: 0.2;
    }

    // }
  }
}


// #above-fold {
//   // height: 100vh;
//   // min-height: 450px;
//   display: flex;
//   flex-flow: column nowrap;
//   height: 100%;
//   height: -webkit-fill-available;
//   // max-height: 100%;
//   // max-height: var(--ih);

//   @media screen and (max-width: 1400px) {
//     // max-height: 1170px;
//   }

//   @media screen and (min-width: 1400px) and (min-height: 1170px) {
//     // max-height: 1270px;
//   }
// }

// .fancybox-content#page-content {
//   height: 100%;
//   padding: 0;
//   @media screen and (min-width: 400px) {
//     padding: 0;
//   }
// }

// .fancybox-content.page-content {
//   // max-width: 1100px;
//   @media screen and (min-width: 1100px){
//     // width: 1000px;
//   }
//   // margin: 0 auto;
//   max-height: 100%;
// }

// .fancybox__content .popup.content {
//   padding: 50px 20px 20px;
//   @media screen and (min-width: 400px) {
//     padding: 50px;
//   }
// }

// .fancybox-slide--html {
//   @media screen and (min-width: 761px) {
//     padding: 44px;
//   }
//   .fancybox-close-small {
//     height: 50px;
//     width: 50px;
//     right: 10px;
//     right: var(--sbw);
//     color: #fff;
//     padding: 0;
//     opacity: 1;
//     background-color: rgb(2, 35, 85);
//     // position: fixed;
//     margin-left: 10px;
//     margin-bottom: 10px;
//   }
// }
// // @media (hover:hover) {
//   .fancybox-close-small:hover {
//     background-color: rgb(32, 65, 115);
//   }
// // }

// .fancybox-button svg path {
//   fill: #fff;
// }

// .fancybox-loading {
//     animation: a 1s linear infinite;
//     background: transparent;
//     border: 4px solid #888;
//     border-bottom-color: #fff;
//     border-radius: 50%;
//     height: 50px;
//     left: 50%;
//     margin: -25px 0 0 -25px;
//     opacity: .7;
//     padding: 0;
//     position: absolute;
//     top: 50%;
//     width: 50px;
//     z-index: 99999
// }

.map.main .loader {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -10;
  background-color: #dedede;
}